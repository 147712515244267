import {createStyles, ActionIcon} from '@mantine/core';
import {LinksGroup} from '../components/layout/LinksGroup';
import {BrandAirtable, BuildingWarehouse, Category, List, Logout, Settings, User, Users} from "tabler-icons-react";
import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useSanctum} from "react-sanctum";
import {checkPermissions} from "../helpers/helpers";

const mockLinks = [
    {label: 'Suppliers', icon: Users, link: '/suppliers'},
    {label: 'Categories', icon: Category, link: '/categories'},
    {label: 'Products', icon: BuildingWarehouse, link: '/products'},
    {label: 'Pri. rules', icon: List, link: '/pricing-rules', permissions: ['view pricing-rules']},
    {label: 'Users', icon: User, link: '/users', permissions: ['view users']},
    {label: 'Settings', icon: Settings, link: '/settings', permissions: ['view roles']},
];

export function NavbarNested() {
    const {classes} = useStyles();
    const location: any = useLocation()
    const [subLinks, setSubLinks] = useState([])
    const navigate = useNavigate()
    const {signOut, user} = useSanctum()


    useEffect(() => {
        mockLinks.map((link: any) => {
            if (location.pathname.includes(link.link)) setSubLinks(link.links)

        })
    }, [location.pathname])

    const onSignOutPress = () => {
        signOut().then(() => navigate('/login'))
    }

    return (<div className={classes.nav_wrapper}>
            <div key={'page_navbar'} className={classes.navbar}>

                <div className={classes.header}>
                    <BrandAirtable strokeWidth={1} size={'100%'}/>
                </div>
                <div className={'nav-links'}>
                    <div>
                        {
                            mockLinks.map((item, index) => item.permissions ? checkPermissions(item.permissions) &&
                                <LinksGroup key={`navlink-${index}`}
                                            {...item}
                                            setSubLinks={setSubLinks}/>
                                : <LinksGroup key={`navlink-${index}`}
                                              {...item}
                                              setSubLinks={setSubLinks}/>)
                        }
                    </div>


                    <div className={'logout-container'}>
                        <ActionIcon color={'#a90000'} onClick={() => onSignOutPress()}>
                            <Logout strokeWidth={1.8}/>
                        </ActionIcon>
                    </div>
                </div>
            </div>
            {
                subLinks && Array.isArray(subLinks) && subLinks.length > 0 &&
                <div className={classes.navbar_extension}>
                    {
                        subLinks.map((item, index) => <div key={`sublink${index}`}>
                            <Link to={item.link}>
                                {item.label}
                            </Link>
                        </div>)
                    }
                </div>
            }
        </div>
    );
}

const useStyles = createStyles((theme) => ({
    nav_wrapper: {
        display: 'flex'
    },
    navbar_extension: {
        width: 220,
        background: '#f2f2f5',
        borderRight: '1px solid #ccd1d8',
        padding: '100px 20px 0 20px',
        marginRight: 15,
        display: 'flex',
        gap: 10,
        flexDirection: 'column'
    },
    navbar: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFF',
        width: 80,
        paddingBottom: 0,
        color: '#e1e1e1',
        borderRight: '1px solid #ccd1d8',
        '.nav-links': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
        },
        '.logout-container': {
            marginTop: '100%',
            padding: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },

    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 80,
        padding: theme.spacing.md,
        color: theme.black,
        marginBottom: 20,
        boxShadow: '0 2px 6px #00000029',
        'img': {
            width: '100%',
            height: '100%',
        }
    },

    links: {
        marginLeft: -theme.spacing.md,
        marginRight: -theme.spacing.md,
    },

    linksInner: {
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
    },

    footer: {
        marginLeft: -theme.spacing.md,
        marginRight: -theme.spacing.md,
        borderTop: `1px solid #41284E`,
    },
}));

