import '../sass/app.scss'
import {MantineProvider} from "@mantine/core";
import {NotificationsProvider} from "@mantine/notifications";
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {ModalsProvider} from "@mantine/modals";

import {MantineThemeObject} from "../theme/themeObject";
import {useEffect, useState} from "react";
import {NavbarNested} from "./layout/Navbar";
import Login from "./pages/auth/Login";
import ProductList from "./pages/products/ProductList";
import ProductShow from "./pages/products/ProductShow";
import SupplierList from "./pages/suppleirs/SupplierList";
import SupplierShow from "./pages/suppleirs/SupplierShow";
import CategoriesList from "./pages/categories/CategoriesList";
import PricingRuleList from "./pages/pricingRules/PricingRuleList";
import UserList from "./pages/user/UserList";
import {Authenticated, RequireAuth, HasPermission} from './providers/AuthProvider'
import {ProfileButton} from "./components/ProfileButton";
import {useSanctum} from "react-sanctum";
import Settings from "./pages/Settings/Settings";

const AppRouter = () => {
    const location = useLocation()
    const [hideTools, setHideTools] = useState(true)
    const {authenticated, user} = useSanctum()


    useEffect(() => {
        if (location.pathname.match('/login|forgot|register\/')) setHideTools(true)
        else setHideTools(false)
    }, [location.pathname])


    return <MantineProvider withGlobalStyles
                            withNormalizeCSS
                            theme={{...MantineThemeObject}}>
        <NotificationsProvider position={'top-right'} autoClose={5000}>
            <ModalsProvider>
                {
                    !hideTools && <NavbarNested/>
                }

                {
                    !hideTools && <ProfileButton/>
                }
                <div style={{position: 'relative', display: 'flex', flex: 1, width: 'calc(100% - 80px)'}}>
                    <Routes>

                        <Route element={<Authenticated/>}>
                            <Route path='login' element={<Login/>}/>

                        </Route>

                        <Route element={<RequireAuth/>}>
                            <Route path='dashboard' element={<div></div>}/>

                            <Route element={<HasPermission user={user}
                                                           requiredPermissions={["view suppliers"]}/>}>
                                <Route path='suppliers' element={<SupplierList/>}/>
                                <Route element={<HasPermission user={user}
                                                               requiredPermissions={["update suppliers"]}/>}>
                                    <Route path='suppliers/:id' element={<SupplierShow/>}/>
                                </Route>
                            </Route>


                            <Route element={<HasPermission user={user}
                                                           requiredPermissions={["view suppliers"]}/>}>
                                <Route path='categories' element={<CategoriesList/>}/>
                            </Route>

                            <Route element={<HasPermission user={user}
                                                           requiredPermissions={["view suppliers"]}/>}>
                                <Route path='pricing-rules' element={<PricingRuleList/>}/>
                            </Route>


                            <Route element={<HasPermission user={user}
                                                           requiredPermissions={["view products", "view roles"]}/>}>
                                <Route path='products' element={<ProductList/>}/>

                                <Route element={<HasPermission user={user}
                                                               requiredPermissions={["update products", "view roles"]}/>}>
                                    <Route path='products/:id' element={<ProductShow/>}/>
                                </Route>
                            </Route>


                            <Route element={<HasPermission user={user}
                                                           requiredPermissions={["view settings", "view roles"]}/>}>
                                <Route path='settings' element={<Settings/>}/>
                            </Route>


                            <Route element={<HasPermission user={user} requiredPermissions={["view users"]}/>}>
                                <Route path='users' element={<UserList/>}/>
                            </Route>
                        </Route>

                        <Route path='*' element={<Navigate to={authenticated ? 'dashboard' : 'login'}
                                                           replace/>}/>

                    </Routes>
                </div>
            </ModalsProvider>
        </NotificationsProvider>
    </MantineProvider>

}

export default AppRouter
