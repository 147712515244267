import {Modal, Button, NumberInput} from "@mantine/core";
import {useBaseObjectForm} from "../customHooks/hooks";
import {getErrorMessage} from "../helpers/helpers";
import {storePricingRule, updatePricingRule} from "../backend/axios";
import {useState} from "react";

const PricingRuleModal = (props) => {
    const {
        open = false,
        type = '',
        onSave = () => {
        },
        setOpen = () => {
        },
        onCreate = () => {
        },
        selected = {},
    } = props

    const [loading, setLoading] = useState(false)
    const getRuleType = () => {
        if (selected) {
            if (selected.product && (selected.product.id || selected.product.name)) {
                return 'product'
            } else if (selected.category && selected.category.id) {
                return 'category'
            } else if (selected.supplier && selected.supplier.id) {
                return 'category'
            } else return ''
        }
    }

    const _type = type ? type : getRuleType()
    const {data, errors, setErrors, onDataChange}: any = useBaseObjectForm(
        {
            discount: '',
            margin: '',
            supplier: '',
            product: '',
            category: '',
            ...selected
        }
    )

    const onSubmit = (e = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)

        if (!selected || !selected.id) {
            storePricingRule({
                supplier_id: data.supplier ? data.supplier : '',
                category_id: data.category && data.category.id ? data.category.id : '',
                product_id: data.product && data.product.id ? data.product.id : data.product ? data.product : '',
                margin: data.margin,
                discount: data.discount
            })
                .then(r => {
                    if (r && r.data && onCreate) onCreate({...r.data})
                    setOpen(false)
                    setLoading(false)
                })
                .catch(e => {
                    if (e && e.response && e.response.data && e.response.data.errors) setErrors(e.response.data.errors)
                    setLoading(false)
                })
        } else {
            updatePricingRule(data.id, data)
                .then(r => {
                    if (r && r.data && onSave) onSave({...r.data, listIndex: data.listIndex})
                    setOpen(false)
                    setLoading(false)
                })
                .catch(e => {
                    if (e && e.response && e.response.data && e.response.data.errors) setErrors(e.response.data.errors)
                    setLoading(false)
                })
        }
    }

    const getTitle = () => {
        let _name = ''
        if (selected && selected.id) {
            _name = 'Edit rule'
            if (_type === 'supplier') _name = _name + ` - ${selected.supplier.name}`
            else if (_type === 'category') _name = _name + ` - ${selected.category.name}`
            else if (_type === 'product') _name = _name + ` - ${selected.product.name}`
        } else {
            _name = 'New rule'
            if (_type === 'supplier') _name = _name + ' supplier'
            if (_type === 'category') _name = _name + ' category'
            if (_type === 'product') _name = _name + ' product'
        }

        return _name
    }

    return <Modal
        title={getTitle()}
        opened={open}
        centered
        onClose={() => setOpen(false)}>
        <form onSubmit={onSubmit}>
            <NumberInput label={'Nuolaida'}
                         disabled={loading}
                         value={data.discount}
                         precision={4}
                         rightSection={'%'}
                         error={getErrorMessage(errors, 'discount')}
                         onChange={(num) => onDataChange({discount: num})}/>
            <NumberInput label={'Marža'}
                         disabled={loading}
                         value={data.margin}
                         precision={4}
                         rightSection={'%'}
                         error={getErrorMessage(errors, 'margin')}
                         onChange={(num) => onDataChange({margin: num})}/>

            <div style={{marginTop: 15}}>
                <Button disabled={loading} loading={loading} size={'xs'} compact type={'submit'}>
                    Submit
                </Button>
            </div>

        </form>

    </Modal>

}
export default PricingRuleModal
