import {API} from "./api";

export const getProducts = (filter: object = undefined, getToken: any = undefined) => {
    return API.get(`/products`, {
        params: filter,
        cancelToken: getToken
    })
}
export const getProduct = (id: string = undefined, getToken: any = undefined) => {
    return API.get(`/products/${id}`, {
        params: {id},
        cancelToken: getToken
    })
}

export const updateProduct = (id: string = undefined, data: object = undefined, getToken: any = undefined) => {
    return API.put(`/products/${id}`, data, {
        cancelToken: getToken
    })
}

export const getSuppliers = (filter: object = undefined, getToken: any = undefined) => {
    return API.get(`/suppliers`, {
        params: filter,
        cancelToken: getToken
    })
}
export const getSupplier = (id: string = undefined, getToken: any = undefined) => {
    return API.get(`/suppliers/${id}`, {
        cancelToken: getToken
    })
}
export const getSupplierTemplate = (id: string = undefined, getToken: any = undefined) => {
    return API.get(`/suppliers/${id}/fields`, {
        cancelToken: getToken
    })
}

export const saveSupplierTemplate = (id: string = undefined, data: any = undefined, getToken: any = undefined) => {
    return API.post(`/suppliers/${id}/fields`, {data: data}, {
        cancelToken: getToken
    })
}
export const deleteSupplierTemplateItem = (orgId, deleteId: string = undefined, getToken: any = undefined) => {
    return API.delete(`/suppliers/${orgId}/fields/${deleteId}`, {
        cancelToken: getToken
    })
}

export const getProductFilters = (getToken: any = undefined) => {
    return API.get(`/getProductFilters`, {
        cancelToken: getToken
    })
}


export const getSupplierCategories = (filter: object = undefined, getToken: any = undefined) => {
    return API.get(`/categories/supplier`, {
        params: filter,
        cancelToken: getToken
    })
}

export const updateCategory = (id: string = undefined, data: object = undefined, getToken: any = undefined) => {
    return API.put(`/categories/${id}`, data, {
        cancelToken: getToken
    })
}

export const getPricingRules = (filter: object = undefined, getToken: any = undefined) => {
    return API.get(`/pricing-rules`, {
        params: filter,
        cancelToken: getToken
    })
}
export const updatePricingRule = (id: string = undefined, data: object = undefined, getToken: any = undefined) => {
    return API.put(`/pricing-rules/${id}`, data, {
        cancelToken: getToken
    })
}

export const storePricingRule = (data: object = undefined, getToken: any = undefined) => {
    return API.post(`/pricing-rules`,
        data,
        {
            cancelToken: getToken
        })
}

export const deletePricingRule = (id: string = undefined, getToken: any = undefined) => {
    return API.delete(`/pricing-rules/${id}`, {
        cancelToken: getToken
    })
}

export const searchProduct = (filter: object = undefined, getToken: any = undefined) => {
    return API.get(`/search/product`, {
        params: filter,
        cancelToken: getToken
    })
}

export const getUsers = (filter: object = undefined, getToken: any = undefined) => {
    return API.get(`/users`, {
        params: filter,
        cancelToken: getToken
    })
}

export const getRoles = (getToken: any = undefined) => {
    return API.get(`/roles`, {
        cancelToken: getToken
    })
}
export const deleteRole = (id: any = undefined, getToken: any = undefined) => {
    return API.delete(`/roles/${id}`, {
        cancelToken: getToken
    })
}

export const updateRoles = (id: string = undefined, data: object = undefined, getToken: any = undefined) => {
    return API.put(`/roles/${id}`, data, {
        cancelToken: getToken
    })
}

export const createRole = (data: object = undefined, getToken: any = undefined) => {
    return API.post(`/roles`, data, {
        cancelToken: getToken
    })
}

export const getPermissions = (getToken: any = undefined) => {
    return API.get(`/permissions`, {
        cancelToken: getToken
    })
}

export const updateUser = (id: string = undefined, data: object = undefined, getToken: any = undefined) => {
    return API.put(`/users/${id}`, data, {
        cancelToken: getToken
    })
}

export const storeUser = (data: object = undefined, getToken: any = undefined) => {
    return API.post(`/users`, data, {
        cancelToken: getToken
    })
}


export const apiApplyToAllProducts = (data: object = undefined, getToken: any = undefined) => {
    return API.post(`/apply-to-all`,
        data,
        {
            cancelToken: getToken
        })
}

export const updateUserProfile = (data: object = undefined, getToken: any = undefined) => {
    return API.put(`/profile`, data, {
        cancelToken: getToken
    })
}

export const updateUserPassword = (data: object = undefined, getToken: any = undefined) => {
    return API.put(`/profile/password`, data, {
        cancelToken: getToken
    })
}
