import {Table, TextInput, ActionIcon, Button, UnstyledButton, createStyles, Pagination} from "@mantine/core";
import {ArrowDown, ArrowsSort, ArrowUp, Filter, Search, X} from "tabler-icons-react";
import {useEffect, useState} from "react";
import {productListCols} from "../../helpers/Settings";
import ProductListColumnSelector from "../../components/inputs/ProductListColumnSelector";
import {useBaseAxiosCancel, useNavbarExtension} from "../../customHooks/hooks";
import ProductFilter from "../../components/ProductFilter";
import {useLocation} from "react-router-dom";

import CategoryNest from "../../components/CategoryNest";
import {openModal} from "@mantine/modals";
import ApplyToAllProducts from "../../components/ApplyToAllProducts";
import {getProducts, getSupplierCategories} from "../../backend/axios";
import ProductTableRow from "../../components/ProductTableRow";
import axios from "axios";
import ProductTableLoadingSkeleton from "../../components/ProductTableLoadingSkeleton";

const defaultColumns = ['id', 'image', 'name', 'stock', 'price', 'status', 'complete']
const sortableColumns: any = ['id', 'name', 'stock', 'price', 'complete', 'status']

const ProductList = () => {
    const {classes} = useStyles()
    const location = useLocation()

    const [productListOptions, setProductListOptions]: any = useState(localStorage.getItem('product_selector_list')
        ? JSON.parse(localStorage.getItem('product_selector_list'))
        : productListCols)

    const navbarExtension = useNavbarExtension()
    const categoriesExtension = useNavbarExtension()

    const [loading, setLoading] = useState(false)
    const {axiosToken, setAxiosToken, searchTimeout, setSearchTimeout}: any = useBaseAxiosCancel()

    const [errors, setErrors]: any = useState({})
    const [data, setData]: any = useState([])
    const [meta, setMeta]: any = useState()

    //Filter hooks
    const [categoryNest, setCategoryNest] = useState()
    const [query, setQuery] = useState('')
    const [sortBy, setSortBy] = useState('')
    const [sortDir, setSortDir] = useState('')
    const [filter, setFilter]: any = useState({
        supplier: '',
        category: '',
        status: '',
        complete: '',
        group: '',
        image: '',
        created_at: '',
        updated_at: '',
        page: 1
    })

    const toggleCategories = (st = undefined) => categoriesExtension.setOpen(st !== undefined ? st : !categoriesExtension.open)

    useEffect(() => {
        if (location && location.state && location.state.filter) {
            setFilter({...filter, ...location.state.filter})
            navbarExtension.setOpen(true)
        }
    }, [])

    useEffect(() => {
        getData()
    }, [query, sortBy, sortDir, filter])

    useEffect(() => {
        if (filter.supplier) getCategories(filter.supplier)
    }, [filter.supplier])

    const [activeColumns, setActiveColumns] = useState(localStorage.getItem('product_columns')
        ? localStorage.getItem('product_columns').split(',')
        : defaultColumns
    )

    const onFilterChange = (values) => {
        let _errors = {...errors}
        Object.keys(values).map(key => {
            delete _errors[key]
        })
        setErrors(_errors)
        setFilter({...filter, ...values})
    }

    const getCategories = (id) => {
        getSupplierCategories({supplier: id, minimal: true}).then(r => {
            setCategoryNest(r.data)
        })
    }

    const onActiveColumnChange = (items) => {
        setActiveColumns(items)
        localStorage.setItem('product_columns', items)
    }

    const setSort = (col) => {
        if (col !== sortBy) {
            setSortBy(col)
            setSortDir('desc')
        } else if (col === sortBy) {
            if (sortDir === 'asc') setSortDir('desc')
            else if (sortDir === 'desc') setSortDir('asc')
            else setSortDir('asc')
        }
    }


    const getData = () => {
        setLoading(true);
        if (searchTimeout) clearTimeout(searchTimeout);
        if (axiosToken !== undefined) axiosToken.cancel();

        let _token = axios.CancelToken.source();
        setAxiosToken(_token);

        setSearchTimeout(
            setTimeout(() => {
                setData([])
                getProducts({
                    query,
                    sortDir,
                    sortBy,
                    ...filter,
                    category: filter.category && filter.category.id ? filter.category.id : ''
                }, _token.token)
                    .then(r => {
                        if (r && r.data && r.data.data) {
                            setData(r.data.data)
                            if (r.data.meta) setMeta(r.data.meta)
                        }
                        setLoading(false)
                    })
                    .catch((error) => {
                        if (axios.isCancel(error)) {
                        } else setLoading(false)
                    })
            }, 500)
        )
    }

    const openApplyToAll = () => {
        openModal({
            centered: true,
            modalId: 'apply-to-all-modal',
            title: 'Apply settings to all filtered products',
            children: (<ApplyToAllProducts filter={filter} refreshPage={getData}/>),
        })
    }

    const onResetColumns = () => {
        setProductListOptions(productListCols)
        localStorage.setItem('product_selector_list', JSON.stringify(productListCols))
        onActiveColumnChange(defaultColumns)
    }
    const onSaveChanges = (item, index) => {
        let _data = [...data]
        _data[index] = item
        setData(_data)

    }

    const getPagination = () => meta && <div className={classes.pagination}>
        <Pagination size={'sm'}
                    radius={12}
                    page={filter && filter.page ? filter.page : 1}
                    onChange={(page) => {
                        onFilterChange({page: page})
                    }}
                    total={meta && meta.last_page ? meta.last_page : 0}/>
    </div>

    return <>

        {
            navbarExtension.open &&
            <div className={classes.extension}>
                <div style={{position: 'relative'}}>
                    <div style={{position: 'absolute', right: 0, top: -30}}>
                        <ActionIcon color={'red'} onClick={() => navbarExtension.setOpen(false)}>
                            <X strokeWidth={1.2} size={22}/>
                        </ActionIcon>
                    </div>
                    <ProductFilter filter={filter}
                                   onFilterChange={onFilterChange}
                                   toggleCategories={toggleCategories}/>
                </div>
            </div>
        }

        {
            categoriesExtension.open && categoryNest &&
            <div className={classes.categoryExtension}>
                <div style={{position: 'relative', marginLeft: -24}}>
                    <div style={{position: 'absolute', right: 0, top: -30}}>
                        <ActionIcon color={'red'} onClick={() => categoriesExtension.setOpen(false)}>
                            <X strokeWidth={1.2} size={22}/>
                        </ActionIcon>
                    </div>
                    <CategoryNest selected={filter.category ? filter.category.id : ''}
                                  setSelected={(cat) => {
                                      onFilterChange({category: cat})
                                      categoriesExtension.setOpen(false)
                                  }}
                                  onFilterChange={onFilterChange}
                                  allowHighlight
                                  nest={categoryNest}/>
                </div>
            </div>
        }

        <section className={'main'}>
            <h5 className={'muted lean-text'}>Products</h5>

            <form onSubmit={getData}>
                <TextInput placeholder={'Search for name or sku'}
                           variant={"unstyled"}
                           value={query}
                           onChange={(e) => setQuery(e.target.value)}
                           styles={{
                               root: {
                                   borderBottom: "1px solid #e8e9ec"
                               },
                               wrapper: {
                                   display: 'flex',
                                   gap: 3,
                                   alignItems: 'center',
                               },
                               rightSection: {
                                   position: 'relative',
                               },
                               input: {
                                   overflow: 'hidden',
                                   textOverflow: 'ellipsis'
                               }
                           }}
                           icon={<Search size={20}
                                         strokeWidth={1.2}/>}/>
            </form>

            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 25,
                position: 'sticky',
                top: 50,
                zIndex: 10
            }}>
                <div style={{background: '#fff', padding: 5}}>
                    <ActionIcon color={'brand'}
                                onClick={() => navbarExtension.setOpen(!navbarExtension.open)}><Filter/></ActionIcon>
                </div>

                <div style={{display: 'flex', gap: 10, alignItems: 'center', background: '#fff', padding: 5}}>
                    <ProductListColumnSelector label={'Columns'}
                                               value={activeColumns}
                                               setOptions={setProductListOptions}
                                               options={productListOptions ? productListOptions : []}
                                               onChange={(items) => onActiveColumnChange(items)}
                                               onResetColumns={onResetColumns}/>

                    <Button size={'xs'}
                            compact
                            variant={'filled'}
                            onClick={() => openApplyToAll()}>Apply to all</Button>
                </div>
            </div>

            {getPagination()}

            <div style={{overflow: 'auto', marginTop: 10, position: 'relative', minHeight: loading ? 150 : 0}}>
                <Table highlightOnHover className={'productListTable'} fontSize={'xs'}
                       style={{minWidth: 800, tableLayout: 'fixed'}}>
                    <thead>
                    <tr>
                        <th style={{width: 50}}/>
                        {
                            productListOptions.map((item, item_index) => {
                                if (activeColumns.includes(item.value)) {
                                    return <th key={`coll_index-${item_index}`} id={`aa-${item.label}`}
                                               style={{minWidth: 80}}>
                                        <UnstyledButton className={classes.th + ' th-header'} onClick={() => {
                                            if (sortableColumns.includes(item.value)) setSort(item.value)
                                        }}>
                                            <div className={'th-header'}>
                                                {item.label}
                                            </div>

                                            {
                                                sortableColumns.includes(item.value) && <div>
                                                    {
                                                        sortBy === item.value
                                                            ? sortDir == 'asc'
                                                                ? <ArrowUp size={12}/>
                                                                : <ArrowDown size={12}/>
                                                            : <ArrowsSort size={12}/>
                                                    }
                                                </div>
                                            }
                                        </UnstyledButton>
                                    </th>
                                }
                            })
                        }
                    </tr>
                    </thead>

                    <tbody>
                    {
                        loading ?
                            <ProductTableLoadingSkeleton columnCount={activeColumns ? activeColumns.length + 1 : 5}
                                                         rowCount={4}/>
                            : data && data.length > 0 && data.map((item, index) => {
                            if (activeColumns && activeColumns.length > 0) return <ProductTableRow index={index}
                                                                                                   onSaveChanges={onSaveChanges}
                                                                                                   item={item}
                                                                                                   productListOptions={productListOptions}
                                                                                                   key={`rowComp-${index}`}
                                                                                                   activeColumns={activeColumns}
                                                                                                   filter={filter}/>
                        })
                    }
                    </tbody>
                </Table>
            </div>
            {getPagination()}
        </section>
    </>
}
export default ProductList

const useStyles = createStyles(() => ({
    th: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'space-between',
        gap: 15,
    },
    extension: {
        position: 'absolute',
        padding: '100px 25px',
        minWidth: 250,
        zIndex: 15,
        background: '#eee',
        height: '100%',
        overflow: 'auto'
    },
    categoryExtension: {
        position: 'absolute',
        padding: '100px 25px',
        minWidth: 260,
        zIndex: 15,
        background: '#eee',
        height: '100%',
        overflow: 'auto',
        top: 0,
        left: 0
    },
    pagination: {
        display: 'flex',
        margin: '15px auto',
        width: 'max-content',
        justifyContent: 'center',
        position: 'sticky',
        background: '#eee',
        top: 0,
        padding: 5,
        borderRadius: 15,
        zIndex: 10
    }
}));

