import {Checkbox, Popover, Stack, Button, createStyles, ActionIcon, Tooltip} from '@mantine/core';
import {useViewportSize} from '@mantine/hooks';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {GridDots, TableOff} from "tabler-icons-react";

const ProductListColumnSelector = ({label = '', options = [], onChange, value = [], setOptions, onResetColumns}: any) => {

    const {classes} = useStyles()
    const {width} = useViewportSize()

    const onChangeCheckbox = (option, checked) => {
        let _values: any = [...value]
        if (checked) _values.push(option.value)
        else _values = _values.filter(item => item !== option.value)

        onChange(_values.map(item => item))
    }

    return <>
        <Popover positionDependencies={[width]}>
            <Popover.Target>
                <Button size={'xs'}
                        compact
                        variant={'outline'}>
                    {label ? label : ''}
                </Button>
            </Popover.Target>
            {
                options && Array.isArray(options) && options.length > 0 && <Popover.Dropdown>

                    <DragDropContext
                        onDragEnd={({destination, source}) => {
                            let _options = [...options]

                            let _temp = _options[source.index]
                            _options.splice(source.index, 1)
                            _options.splice(destination.index, 0, _temp)
                            setOptions(_options)

                            let _aa = _options.map(item => {
                                if (value.includes(item.value)) return item.value
                            }).filter(i => {
                                if (i) return i
                            })
                            onChange(_aa)
                            localStorage.setItem('product_selector_list', JSON.stringify(_options))
                        }}>
                        <Droppable key={'droppableListWrapper'} droppableId="dnd-list" direction="vertical">
                            {(provided) => (
                                <Stack spacing={15} {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        options.map((option, index) => <Draggable key={`draggableItem-${option.value}`}
                                                                                  index={index}
                                                                                  draggableId={`trackDrag-${option.value}`}>
                                                {(provided) => (
                                                    <div {...provided.draggableProps} ref={provided.innerRef}>
                                                        <div className={classes.optionWrapper}>
                                                            <div
                                                                className={classes.dragHandle} {...provided.dragHandleProps}>
                                                                <GridDots size={18}/>
                                                            </div>

                                                            <Checkbox key={`checkbox-option-${index}`}
                                                                      label={option.label}
                                                                      checked={value.some(op => option.value === op)}
                                                                      styles={{
                                                                          root: {
                                                                              display: 'flex'
                                                                          },
                                                                          label: {
                                                                              fontSize: '0.9em'
                                                                          }
                                                                      }}
                                                                      onChange={(e) => onChangeCheckbox(option, e.target.checked)}/>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    }
                                    {provided.placeholder}
                                </Stack>
                            )}
                        </Droppable>

                    </DragDropContext>

                    <Tooltip label={'Reset columns'} style={{fontSize: 12}}>
                        <ActionIcon size={'sm'} mt={10} ml={'auto'} onClick={() => onResetColumns()}>
                            <TableOff/>
                        </ActionIcon>
                    </Tooltip>
                </Popover.Dropdown>
            }
        </Popover>
    </>
}

export default ProductListColumnSelector

const useStyles = createStyles((theme, _params) => ({
    optionWrapper: {
        display: 'flex',
        gap: 5,
        alignItems: 'center'
    },

    dragHandle: {
        ...theme.fn.focusStyles(),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    }
}))
