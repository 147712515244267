import {useBaseAxiosCancel, useBaseObjectForm} from "../customHooks/hooks";
import {Button, Loader, LoadingOverlay, MultiSelect, TextInput} from "@mantine/core";
import {apiApplyToAllProducts, getProducts} from "../backend/axios";
import {checkIfResponseHasErrors, getErrorMessage} from "../helpers/helpers";
import {useState} from "react";
import {closeModal} from "@mantine/modals";
import axios from "axios";

const ApplyToAllProducts = ({filter = undefined, refreshPage = undefined}) => {
    const {data, onDataChange, errors, setErrors}: any = useBaseObjectForm({
        bic: '',
        mat_group: [],
        mat_type: []
    })
    const [loading, setLoading] = useState(false)
    const {axiosToken, setAxiosToken, searchTimeout, setSearchTimeout}: any = useBaseAxiosCancel()

    const onSubmit = (e) => {
        if (e) e.preventDefault()
        setLoading(true)

        if (searchTimeout) clearTimeout(searchTimeout);
        if (axiosToken !== undefined) axiosToken.cancel();

        let _token = axios.CancelToken.source();
        setAxiosToken(_token);

        setSearchTimeout(
            setTimeout(() => {
                apiApplyToAllProducts({
                    filter: filter ? filter : null,
                    bic: data.bic,
                    mat_group: data.mat_group && data.mat_group.length > 0 ? data.mat_group : '',
                    mat_type: data.mat_type && data.mat_type.length > 0 ? data.mat_type : '',
                })
                    .then(r => {
                        if (r && r.data) {
                            console.log(r.data)
                            if (refreshPage) refreshPage()
                        }
                        closeModal('apply-to-all-modal')
                        setLoading(false)
                    })
                    .catch(e => {
                        if (checkIfResponseHasErrors(e)) {
                            setErrors(e.response.data.errors)
                        }
                        setLoading(false)
                    })
            }, 50)
        )
    }

    return <form onSubmit={onSubmit}>
        <LoadingOverlay visible={loading} loader={<div
            style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Loader/>
            <div style={{padding: 15, textAlign: 'center', fontSize: 13}}>Action may take few minutes.</div>
        </div>}/>
        <TextInput label={'BIC'}
                   value={data.bic}
                   disabled={loading}
                   errors={getErrorMessage(errors, 'bic')}
                   onChange={(e) => onDataChange({bic: e.target.value})}/>

        <MultiSelect label={'Material group'}
                     value={data.mat_group}
                     disabled={loading}
                     errors={getErrorMessage(errors, 'mat_group')}
                     onChange={(it) => onDataChange({mat_group: it})}
                     data={['gr1', 'gr2', 'gr3']}/>

        <MultiSelect label={'Material type'}
                     value={data.mat_type}
                     disabled={loading}
                     errors={getErrorMessage(errors, 'mat_type')}
                     onChange={(it) => onDataChange({mat_type: it})}
                     data={['type1', 'type2', 'type3']}/>

        <Button fullWidth mt="md" type={"submit"} loading={loading}>
            Apply
        </Button>
    </form>
}

export default ApplyToAllProducts
