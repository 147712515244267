import {getErrorMessage} from "../../helpers/helpers";
import {createStyles, PasswordInput, TextInput, Button, Checkbox} from "@mantine/core";
import {useBaseObjectForm} from "../../customHooks/hooks";
import {useSanctum} from "react-sanctum";
import {useEffect, useRef, useState} from "react";

const Login = () => {
    const {classes} = useStyles()
    const [loading, setLoading] = useState(false)
    const {data, errors, setErrors, onDataChange}: any = useBaseObjectForm({
        email: '',
        password: '',
        remember: false
    })
    const {signIn} = useSanctum();

    const passwordInputRef: any = useRef();
    const emailInputRef: any = useRef();

    const [ffr, sffr] = useState(null)

    const submitLogin = (e) => {
        e.preventDefault()
        setLoading(true)
        signIn(data.email, data.password, data.remember)
            .then(() => {
                setLoading(false)
            })
            .catch(r => {
                passwordInputRef.current.focus();
                if (r.response.status === 422) {
                    setErrors(r.response.data.errors)
                    sffr(!ffr);
                }
                setLoading(false)
            })
    }

    useEffect(() => {
        if (ffr !== null) passwordInputRef.current.focus()
        else emailInputRef.current.focus()
    }, [ffr])

    return <div className={classes.page}>
        <div className={classes.form_wrapper}>
            <div className={classes.header}>
                <label className={'heading__text'}>Login</label>
            </div>

            <form onSubmit={submitLogin}>
                <TextInput label={'Email'}
                           disabled={loading}
                           error={getErrorMessage(errors, 'email')}
                           value={data.email}
                           ref={emailInputRef}
                           onChange={(e) => onDataChange({email: e.target.value})}/>

                <PasswordInput label={'Password'}
                               disabled={loading}
                               error={getErrorMessage(errors, 'password')}
                               value={data.password}
                               ref={passwordInputRef}
                               onChange={(e) => onDataChange({password: e.target.value})}/>

                <Checkbox style={{marginTop: 10}} label={'Prisiminti mane'}
                          checked={data.remember} onChange={() => onDataChange({remember: !data.remember})}/>


                <div className={classes.button_placement}>
                    <Button className={classes.button} type={'submit'} loading={loading}>Login</Button>
                </div>
            </form>
        </div>
    </div>
}

export default Login

const useStyles = createStyles((theme) => ({
    button: {
        padding: '8px 32px',
        fontSize: '.875rem',
        fontWeight: 600,
    },
    header: {
        padding: '1rem 0 1.2rem',
        '.heading__text': {
            color: '#1A0326',
            padding: '1rem 0 1.2rem',
            fontSize: '1.875rem',
            fontWeight: 500,
        }
    },
    page: {
        flex: 1,
        width: '100vw',
        boxSizing: 'border-box',
        minHeight: '100vh',
        backgroundColor: '#f7f8fb',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    muted_link: {
        fontSize: '0.75rem',
        color: theme.colors.gray[6]
    },
    form_wrapper: {
        boxShadow: '4px 4px 16px 0 rgb(77 83 224 / 20%)',
        backgroundColor: theme.white,
        padding: '24px 32px',
        maxWidth: 450,
        width: '100%',
        borderRadius: 4,
        height: 'max-content',
        marginBottom: 20,
        transition: 'max-height 0.5s',
        'h2': {
            color: theme.colors.brand[6],
            textShadow: '1px 1px 1px rgb(0 0 0 / 10%)',
            marginTop: 5
        }
    },
    button_placement: {
        marginTop: 20,
        textAlign: 'center'
    }
}));
