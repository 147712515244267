export const categoryListMock = {
    id: 1,
    name: 'AVARO',
    nest: [
        {
            id: 2,
            name: 'Knygos',
            nest: [
                {id: 4, name: 'Knyga #1', status: 'enabled', nest: [{id: 5, name: 'Knyga #1'}]},
                {id: 6, name: 'Knyga #2', status: 'enabled'}],
            status: 'enabled'
        },
        {
            id: 3,
            name: 'Dovanos',
            nest: [
                {
                    id: 7, name: '#1', nest: [{id: 8, name: '#1'}, {id: 9, name: '#2'}], status: 'enabled'
                }, {id: 10, name: '#2', status: 'enabled'}],
            status: 'disabled'
        },
        {
            id: 11,
            name: 'Knygos',
            status: 'enabled',
            nest: [{id: 12, name: '#1', status: 'enabled'}, {id: 13, name: '#2', status: 'disabled'}]
        }
    ]
}
export const categoryListMock2 = {
    id: 14,
    name: 'Pegasas',
    nest: [
        {
            id: 15,
            name: 'Knygos',
            nest: [
                {id: 16, name: 'Knyga #123', status: 'enabled', nest: [{id: 19, name: 'Knyga #16'}]},
                {id: 17, name: 'Knyga #255', status: 'enabled'}],
            status: 'enabled'
        },
        {
            id: 18
            , name: 'Dovanos',
            status: 'disabled'
        },
    ]
}

export const supplierListMock = [
    {id: 1, name: 'AVARO', status: 'active', status_text: 'active', value: '1', label: 'AVARO'},
    {id: 2, name: 'Pegasus', status: 'active', status_text: 'active', value: '2', label: 'Pegasus'},
    {id: 3, name: 'Alma', status: 'disabled', status_text: 'disabled', value: '3', label: 'Alma'},
]
export const userListMock = [
    {id: 1, name: 'User#1', status: 'active', status_text: 'active', roles: ['admin', 'user']},
    {id: 2, name: 'User#2', status: 'active', status_text: 'active', roles: null},
    {id: 3, name: 'User#3', status: 'disabled', status_text: 'disabled', roles: null},
]

export const pricingMarginMock = [

    {
        id: '2',
        sku: 456,
        product: {
            id: 1,
            name: 'monopolis'
        },
        wholesale_price: '100',
        discount: 19.25,
        prime: 80.75,
        margin: 40,
        base_price: 134.6,
        supplier: {
            id: '1',
            name: 'Name1'
        }
    },
    {
        id: '1',
        margin: 40,
        supplier: {
            id: '1',
            name: 'Name1'
        }
    },
    {
        id: '33122',
        sku: 457,
        product: {
            id: 2,
            name: 'Kalendorius stalinis MEMO PVC 2023, metalo žalia'
        },
        wholesale_price: '100',
        discount: 19.25,
        prime: 80.75,
        margin: 40,
        base_price: 111.6,
        supplier: {
            id: '2',
            name: 'Name2'
        }
    },
    {
        id: '42344',
        category: {
            id: '1',
            name: 'stalo žaidimai'
        },
        discount: 5,
        margin: 25,
        supplier: {
            id: '2',
            name: 'Name2'
        }
    },
    {
        id: '5',
        category: {
            id: '2',
            name: 'Dėlionės'
        },
        margin: 15,
        supplier: {
            id: '3',
            name: 'Name3'
        }
    }
]
