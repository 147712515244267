import {createStyles, Divider, MultiSelect, Select, Button, UnstyledButton, TextInput} from "@mantine/core";
import {DateRangePicker} from "@mantine/dates";
import {getSuppliers} from "../backend/axios";
import {useEffect, useState} from "react";
import {X} from "tabler-icons-react";

const ProductFilter = ({toggleCategories, filter, onFilterChange}) => {
    const {classes} = useStyles()
    const [loading, setLoading] = useState(false)

    const [supplierOptions, setSupplierOptions] = useState([])
    const getData = (e = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        getSuppliers()
            .then(r => {
                if (r && r.data) {
                    if (r.data) setSupplierOptions(r.data.map(item => {
                        return {value: `${item.id}`, label: item.name}
                    }))
                }
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [])


    return <>

        <div className={classes.wrapper}>

            <Select label='SUPPLIER'
                    placeholder={'Supplier'}
                    variant={'unstyled'}
                    data={supplierOptions}
                    value={filter.supplier}
                    disabled={loading}
                    onChange={(op) => onFilterChange({supplier: op})}
            />

            <UnstyledButton disabled={!filter.supplier}>
                <TextInput label='CATEGORY'
                           placeholder={'Category'}
                           variant={'unstyled'}
                           data={[]}
                           readOnly
                           onClick={() => toggleCategories()}
                           value={filter.category && filter.category.name ? filter.category.name : ''}
                           onChange={() => {
                           }}
                           rightSection={filter.category && filter.category.id &&
                               <X strokeWidth={1} size={18} color={'red'} onClick={() => {
                                   onFilterChange({category: ''})
                                   toggleCategories(false)
                               }}/>}
                           disabled={!filter.supplier || loading}
                />
            </UnstyledButton>

            <Divider/>

            <div className={classes.filterButtons}>
                <Button fullWidth compact size={'xs'} variant={'outline'}>Clear</Button>
                <Button fullWidth compact size={'xs'}>Apply</Button>
            </div>

            <Select label='STATUS'
                    placeholder={'Status'}
                    variant={'unstyled'}
                    disabled={loading}
                    value={filter.status}
                    onChange={(op) => onFilterChange({status: op})}
                    data={[
                        {value: '1', label: 'Enabled'},
                        {value: '0', label: 'Disabled'},
                    ]}/>
            <Select label='COMPLETE'
                    disabled={loading}
                    placeholder={'Complete'}
                    variant={'unstyled'}
                    data={[
                        {value: 'enabled', label: 'Enabled'},
                        {value: 'disabled', label: 'Disabled'},
                    ]}/>

            <MultiSelect label='GROUPS'
                         disabled={loading}
                         placeholder={'Groups'}
                         variant={'unstyled'}
                         data={[
                             {label: 'Group #1', value: 'gr_1'},
                             {label: 'Group #2', value: 'gr_2'},
                             {label: 'Group #3', value: 'gr_3'},
                         ]}/>
            <Select label='IMAGE'
                    disabled={loading}
                    placeholder={'Image'}
                    variant={'unstyled'}
                    value={filter.image}
                    onChange={(op) => onFilterChange({image: op})}
                    data={[
                        {value: '1', label: 'Yes'},
                        {value: '0', label: 'No'},
                    ]}/>

            <DateRangePicker label='CREATED AT'
                             disabled={loading}
                             placeholder={'Created at'}
                             variant={'unstyled'}
                             styles={{
                                 dropdown: {
                                     position: 'fixed'
                                 }
                             }}/>

            <DateRangePicker label='UPDATED AT'
                             disabled={loading}
                             placeholder={'Updated at'}
                             variant={'unstyled'}
                             styles={{
                                 dropdown: {
                                     position: 'fixed'
                                 }
                             }}/>
        </div>
    </>
}

export default ProductFilter

const useStyles = createStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,

    },
    filterButtons: {
        width: '100%',
        display: 'flex',
        // position: 'absolute',
        top: 35,
        left: 0,
        gap: 10,
        // padding:'10px 20px'
    }
}));
