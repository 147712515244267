export const MantineThemeObject: any = {
    cursorType: 'pointer',
    fontFamily: `'Poppins', sans-serif`,
    defaultRadius: 2,
    loader: 'oval',
    colors: {
        primary: [
            '#edf2ff',
            // variant='light' background color
            // variant='outline background color dimmed
            // variant='subtle hover background color

            '#edf2ff',
            //reacts to variant='light' hover background color

            '#edf2ff',
            //reacts to variant='light/subtle' font color

            '#e8e8e8',
            '#5f3dc4',

            '#ff0000',
            // default shade is considered to be the body background color, input border
            // reacts to variant='outlined'

            '#5f3dc4',
            // default primary color

            '#5033a2',
            // default hover color


            '#5f3dc4',
            // default dark scheme primary color

            '#5033a2',
            // default dark scheme hover color,
        ],
        brand: [
            // '#0b021a', // shade is used as text color with dark color scheme
            '#FEFAFB',
            // variant='light' background color
            // variant='outline background color dimmed
            // variant='subtle hover background color

            '#FEE7EE',
            //reacts to variant='light' hover background color

            '#dobfff',
            //reacts to variant='light/subtle' font color

            '#FB88AA',
            '#D60644',

            '#BA053B',
            // default shade is considered to be the body background color, input border
            // reacts to variant='outlined'

            '#a70535',
            // default primary color

            '#93042E',
            // default hover color

            '#a70535',
            // default dark scheme primary color

            '#93042E',
            // default dark scheme hover color,
        ],
        dark: [
            '#C1C2C5',
            '#A6A7AB',
            '#909296',
            '#5C5F66',
            '#373A40',
            '#2C2E33',
            '#25262B',
            '#1A1B1E',
            '#141517',
            '#1A0326'
        ],
        gray: [
            '#F8F9FA',
            '#F1F3F5',
            '#E9ECEF',
            '#DEE2E6',
            '#CED4DA',
            '#ADB5BD',
            '#868E96',
            '#495057',
            '#343A40',
            '#212529'
        ]
    },
    primaryColor: 'brand',
    datesLocale: 'lt',
    defaultFo: '',
    activeStyles: {transform: 'scale(0.98)'},
    components: {
        Button: {
            styles: (theme) => ({
                label: {
                    fontWeight: 500,
                },
                root:{
                    padding:'0 15px'
                }
            }),
        },
        TextInput: {
            styles: (theme) => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
        Select: {
            styles: (theme) => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '&::placeholder': {}
                },
                item: {
                    fontSize: 12,
                }
            }),
        },
        MultiSelect: {
            styles: (theme) => ({
                label: {
                    fontSize: 12,
                },
                searchInput: {
                    fontSize: 12,
                    '&::placeholder': {
                        fontSize: 12,
                    }
                },
                item: {
                    fontSize: 12,
                }
            }),
        },
        Textarea: {
            styles: (theme) => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
        PasswordInput: {
            styles: (theme) => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
        InputWrapper: {
            styles: (theme) => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
        DatePicker: {
            defaultProps: {
                firstDayOfWeek: 'monday',
                inputFormat: 'YYYY-MM-DD',
                labelFormat: 'YYYY-MM-DD'
            },
            styles: (theme) => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
        DateRangePicker: {
            defaultProps: {
                firstDayOfWeek: 'monday',
                inputFormat: 'YYYY-MM-DD',
                labelFormat: 'YYYY-MM-DD'
            },
            styles: (theme) => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
    }
}
