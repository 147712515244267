import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {createStyles, useMantineTheme, Badge, Table, Button, LoadingOverlay, ActionIcon} from "@mantine/core";
// @ts-ignore
import plaImage from './../../../images/500.png'
import NavbarExtension from "../../components/layout/NavbarExtension";
import {useBaseObjectForm, useNavbarExtension} from "../../customHooks/hooks";
import ProductAttributes from "./subPages/ProductAttributes";
import ProductShowExtension from "./ProductShowExtension";
import PricingRuleModal from "../../components/PricingRuleModal";
import {deletePricingRule, getProduct, updateProduct} from "../../backend/axios";
import {Carousel} from "@mantine/carousel";
import {Pencil, Trash} from "tabler-icons-react";
import {openConfirmModal} from "@mantine/modals";
import {showNotification} from "@mantine/notifications";

const ProductShow = () => {

    const {classes} = useStyles()
    const params = useParams()
    const theme = useMantineTheme()
    const navbarExtension = useNavbarExtension()
    const location = useLocation()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(location.state && location.state.tab ? location.state.tab : 'attributes')
    const [openNewRule, setOpenNewRule] = useState(false)

    const {data, onDataChange, setData, errors, setErrors} = useBaseObjectForm({})

    const getData = (e = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        getProduct(params.id)
            .then(r => {
                if (r && r.data) setData(r.data)

                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {

        if (location.state && location.state.filter) window.onpopstate = () => {
            navigate('/products', {state: {filter: location.state.filter}})
        }

        getData()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000);
    }, [])

    const onSave = () => {
        getData()
        setOpenNewRule(false)
    }

    const onDeleteRule = (item) => {
        openConfirmModal({
            title: `Confirm rule delete`,
            labels: {confirm: 'Delete', cancel: 'Cancel'},
            centered: true,
            confirmProps: {
                compact: true,
                variant: 'white', size: 'xs'
            },
            cancelProps: {
                compact: true,
                variant: 'filled', size: 'xs'
            },
            onConfirm: () => onDelete(item),
        })
    }

    const onDelete = (item) => {
        if (item && item.id) deletePricingRule(item.id)
            .then(r => {
                if (r && r.status === 200) {
                    // setData({...data, pricing_rule: null})
                    getData()
                    showNotification({color: 'red', message: 'Rule deleted'})
                }
            })
            .catch(e => {
                if (e && e.response && e.response.status) showNotification({
                    color: 'red',
                    message: `Error ${e.response.status}`
                })
            })
    }

    const onSaveAttributes = (e) => {
        if (e) e.preventDefault()
        setLoading(true)
        updateProduct(data.id, data)
            .then(r => {
                if (r && r.data) {
                    setData(r.data)
                    setLoading(false)
                }
            })
            .catch(e => {
                if (e && e.response && e.response.data && e.response.data.errors) setErrors(e.response.data.error)
                setLoading(false)
            })
    }

    return <>
        <LoadingOverlay visible={loading}/>

        <NavbarExtension {...navbarExtension} open={true} width={250}>
            <ProductShowExtension activeTab={activeTab}
                                  setActiveTab={setActiveTab}
                                  data={data}
                                  onDataChange={onDataChange}/>
        </NavbarExtension>

        {
            openNewRule && <PricingRuleModal open={openNewRule}
                                             setOpen={setOpenNewRule}
                                             onSave={onSave}
                                             onCreate={onSave}
                                             selected={
                                                 {
                                                     product: data.id,
                                                     id: data.pricing_rule && data.pricing_rule.id ? data.pricing_rule.id : '',
                                                     margin: data.pricing_rule && data.pricing_rule.margin ? data.pricing_rule.margin : '',
                                                     discount: data.pricing_rule && data.pricing_rule.discount ? data.pricing_rule.discount : ''
                                                 }
                                             }/>
        }
        <LoadingOverlay visible={false}/>

        <section className={'main'}>
            <div className={classes.header}>
                <div className={classes.imageBorder}>
                    {
                        data && data.images && data.images.length > 0
                            ?
                            <><Carousel sx={{width: 180, height: 180}} withIndicators height={180} loop>
                                {
                                    data.images.map((item, index) => <Carousel.Slide key={`image-${index}`}>
                                            <img src={item} alt={'missing'} width={'100%'} height={'100%'}/>
                                        </Carousel.Slide>
                                    )
                                }
                            </Carousel></>
                            :
                            <img src={'https://via.placeholder.com/1500x1500'} alt={'missing'} width={100}
                                 height={100}/>
                    }
                </div>

                <div>
                    <label className={classes.header_label}>{`PRODUCTS / ${params.id}`}</label>
                    <div className={classes.header_name}>{data.name}</div>
                    {data.supplier && data.supplier.text &&
                        <div className={classes.header_label}>
                            <span>Supplier:</span>
                            <span style={{marginLeft: 5, color: theme.colors.brand[6]}}>
                                {data.supplier.text}
                            </span>
                        </div>
                    }

                    <Badge color={data.complete === 100 ? 'green.8' : 'yellow.7'}
                           styles={{root: {fontWeight: 500}}}
                           variant={data.complete === 100 ? 'filled' : 'dot'}>
                        {`Complete: ${data.complete ? data.complete : '--'}%`}
                    </Badge>

                </div>
            </div>

            {activeTab === 'attributes' &&
                <ProductAttributes classes={classes}
                                   data={data}
                                   errors={errors}
                                   onSaveAttributes={onSaveAttributes}
                                   onDataChange={onDataChange}/>}
            {
                activeTab === 'pricing_rule' && <>
                    <section className={classes.product_section}>
                        <div className={'product__section_name'}>
                            Pricing rule

                            <Button size={'xs'} color={'brand'} compact variant={'light'}
                                    onClick={() => setOpenNewRule(true)}>
                                Add new
                            </Button>
                        </div>

                        <div className={'product__section_content'}/>

                        {
                            data.pricing_rule && data.pricing_rule.id &&
                            <div className={'product__section_content'}>
                                <Table className={'ruleListTable'}>
                                    <thead>
                                    <tr>
                                        <th>Discount</th>
                                        <th>Margin</th>
                                        <th style={{width: '0'}}></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{`${data.pricing_rule.discount} %`}</td>
                                        <td>{`${data.pricing_rule.margin} %`}</td>
                                        <td>
                                            <div style={{display: 'flex', gap: 5}}>
                                                <ActionIcon size={22} color={'red.6'}
                                                            onClick={() => onDeleteRule(data.pricing_rule)}>
                                                    <Trash size={25} strokeWidth={1.2}/>
                                                </ActionIcon>
                                                <ActionIcon size={22} color={'gray.6'}
                                                            onClick={() => setOpenNewRule(true)}>
                                                    <Pencil size={25} strokeWidth={1.2}/>
                                                </ActionIcon>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        }

                    </section>
                </>
            }

        </section>
    </>
}
export default ProductShow

const useStyles = createStyles((theme) => ({
    header: {
        display: 'flex',
        gap: 15,
        flexWrap: 'wrap',
    },
    header_label: {
        fontSize: 12,
        fontWeight: 400,
        color: 'rgb(126 129 135)'
    },
    header_name: {
        color: theme.colors.brand[6],
        fontSize: '1.2rem'
    },
    product_section: {
        marginTop: 20,
        '.product__section_name': {
            display: 'flex',
            gap: 5,
            alignItems: 'end',
            paddingBottom: 5,
            marginBottom: 5,
            textTransform: 'uppercase',
            fontSize: '0.75rem',
            fontWeight: 500,
            borderBottom: '1px solid #e0e0e0'
        },
        '.product__section_content': {
            maxWidth: 500
        }
    },
    imageBorder: {
        border: '1px solid #ebebeb',
        padding: 3,
        width: "max-content",
        height: 'max-content'
    }
}));
