import {createStyles, Modal, PasswordInput, TextInput, Button} from "@mantine/core";
import {useSanctum} from "react-sanctum";
import {useEffect, useState} from "react";
import {useBaseObjectForm} from "../customHooks/hooks";
import {getErrorMessage, getResponseErrors} from "../helpers/helpers";
import {updateUserPassword, updateUserProfile} from "../backend/axios";
import {showNotification} from "@mantine/notifications";

export const ProfileButton = () => {
    const {classes} = useStyles()
    const {user, setUser} = useSanctum()
    const [openProfileModal, setOpenProfileModal] = useState(false)

    const {data, setData: setUserData, setErrors, errors, onDataChange, loading, setLoading} = useBaseObjectForm({
            first_name: '',
            last_name: '',
            email: ''
    })

    const {
        data: passData,
        setData: setPassData,
        setErrors: setPassErrors,
        errors: passErrors,
        onDataChange: onPassChange,
        loading: passLoading,
        setLoading: setPassLoading
    } = useBaseObjectForm({
        old_password: '',
        password: '',
        password_confirmation: ''
    })

    useEffect(() => {
        if (user && user.id) setUserData(user)
    }, [user])


    const saveProfile = (e) => {
        if (e) e.preventDefault()
        setLoading(true)
        updateUserProfile(data)
            .then(r => {
                if (r && r.status === 200) {
                    showNotification({color: 'green', message: 'Profile updated'})
                    setUser(r.data)
                }
                setLoading(false)
            })
            .catch(e => {
                setErrors(getResponseErrors(e))
                setLoading(false)
            })

    }

    const savePassword = (e) => {
        if (e) e.preventDefault()
        setPassLoading(true)
        updateUserPassword(passData)
            .then(r => {
                if (r && r.status === 200) {
                    showNotification({color: 'green', message: 'Password changed'})
                    setPassData({password: '', old_password: '', password_confirmation: ''})
                }
                setPassLoading(false)
            })
            .catch(e => {
                setPassErrors(getResponseErrors(e))
                setPassLoading(false)
            })
    }

    return <>
        {
            user && user.id && <>
                <div className={classes.button} onClick={() => setOpenProfileModal(true)}>
                    {`${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`}
                </div>


                <Modal title={`Profile: ${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`}
                       opened={openProfileModal}
                       onClose={() => setOpenProfileModal(false)}
                       centered={true}
                       styles={{modal: {maxWidth: 750, width: '100%'}}}>

                    <div style={{display: 'flex', gap: 50, width: '100%'}}>
                        <div style={{flex: 1}}>
                            <h4>User</h4>
                            <form onSubmit={saveProfile}>
                                <div>
                                    <TextInput label={'First name'}
                                               disabled={passLoading || loading}
                                               value={data.first_name}
                                               onChange={(e) => onDataChange({first_name: e.target.value})}
                                               error={getErrorMessage(errors, 'first_name')}/>

                                    <TextInput label={'Last name'}
                                               disabled={passLoading || loading}
                                               value={data.last_name}
                                               onChange={(e) => onDataChange({last_name: e.target.value})}
                                               error={getErrorMessage(errors, 'last_name')}/>

                                    <TextInput label={'Email'}
                                               disabled={passLoading || loading}
                                               value={data.email}
                                               onChange={(e) => onDataChange({email: e.target.value})}
                                               error={getErrorMessage(errors, 'email')}/>
                                </div>
                                <Button size={"xs"} mt={15} compact type={'submit'}
                                        loading={passLoading || loading}>Save</Button>
                            </form>
                        </div>

                        <div style={{flex: 1}}>

                            <h4>Change password</h4>
                            <form onSubmit={savePassword}>
                                <div>
                                    <PasswordInput label={'Old password'}
                                                   disabled={passLoading || loading}
                                                   value={passData.old_password}
                                                   onChange={(e) => onPassChange({old_password: e.target.value})}
                                                   error={getErrorMessage(passErrors, 'old_password')}/>

                                    <PasswordInput label={'New password'}
                                                   disabled={passLoading || loading}
                                                   value={passData.password}
                                                   onChange={(e) => onPassChange({password: e.target.value})}
                                                   error={getErrorMessage(passErrors, 'password')}/>

                                    <PasswordInput label={'Confirm new password'}
                                                   disabled={passLoading || loading}
                                                   value={passData.password_confirmation}
                                                   onChange={(e) => onPassChange({password_confirmation: e.target.value})}
                                                   error={getErrorMessage(passErrors, 'password_confirmation')}/>
                                </div>
                                <Button size={"xs"} mt={15} compact type={'submit'} loading={passLoading || loading}>Change
                                    password</Button>
                            </form>
                        </div>
                    </div>
                </Modal>
            </>
        }
    </>
}
const useStyles = createStyles(() => ({
    button: {
        position: 'absolute',
        top: 25,
        right: 35,
        background: '#eee',
        border: '1px solid #eee',
        padding: '2px 10px',
        fontSize: 13,
        borderRadius: 10,
        zIndex: 100,
        cursor: 'pointer',
        textDecoration: 'none',
        color: '#000',
        fontWeight: 500,
        '&:hover': {
            background: '#fff'
        }
    },
}));
