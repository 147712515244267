import {createStyles, Input, Loader, Popover, ScrollArea, Text, TextInput} from "@mantine/core";
import {useEffect, useState} from "react";
import {Search} from "tabler-icons-react";
import {useClickOutside} from "@mantine/hooks";

const useStyles = createStyles((theme, _params) => ({
    optionItem: {
        fontSize: '0.80rem',
        padding: '5px 10px',
        margin: 2,
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.brand[6],
            color: '#F0F0F0',
        }
    }
}))

const SearchInput = ({
                         label,
                         error,
                         query,
                         onSearchChange,
                         onSelectOption,
                         options,
                         disabled = false,
                         loading = false,
                         customOptionLabelField = '',
                         customOptionLabelDisplay = undefined,
                         placeholder = '',
                         required = false,
                     }: any) => {
    const {classes} = useStyles()
    const [opened, setOpened] = useState(false)
    const menuRef = useClickOutside(() => setOpened(false));

    const InputIcon = () => {
        if (loading) return <Loader size={'sm'}/>
        else return <Search/>
    }

    const onChange = (e) => {
        if (onSearchChange) onSearchChange(e.target.value)
    }

    useEffect(() => {
        setOpened(true)
    }, [options])

    const onSelectLocalOption = (op, index) => {
        setOpened(false)
        onSelectOption(op, index)
    }

    return <Input.Wrapper label={label}
                          required={required}
                          styles={{
                              label: {
                                  width: '100%',
                                  display: 'flex',
                                  gap: 3,
                                  marginTop: 3,
                                  // justifyContent: 'space-between',
                                  alignItems: 'center'
                              }
                          }}>

        <div ref={menuRef}>
            <Popover width="target" position="bottom" shadow="md"
                     disabled={disabled}
                     opened={opened}
                     styles={(theme) => ({
                         dropdown: {
                             padding: 3,
                             border: `1px solid ${theme.colors.brand[6]}`,
                             maxHeight: 300,
                             overflowY: 'auto'
                         }
                     })}>
                <Popover.Target>

                    <TextInput icon={<InputIcon/>}
                               disabled={disabled}
                               name={'search'}
                               key={'search'}
                               value={query}
                               error={error}
                               required={required}
                               placeholder={placeholder ? placeholder : 'Ieškoti...'}
                               onChange={(e) => onChange(e)}/>
                </Popover.Target>

                {
                    options && options.length > 0 &&
                    <Popover.Dropdown>
                        <ScrollArea.Autosize maxHeight={400}>
                            {
                                (!options || options.length < 1) ?
                                    <Text size="sm" color={'dimmed'} align="center">
                                        Pasirinkimų nėra
                                    </Text>
                                    :
                                    <div>
                                        {
                                            options && options.length > 0 && options.map((op: { label: string }, index) => {
                                                return <div key={`option-dropdown-${index}`}
                                                            className={classes.optionItem}
                                                            onClick={() => onSelectLocalOption(op, index)}>
                                                    {
                                                        customOptionLabelField
                                                            ? customOptionLabelDisplay
                                                                ? customOptionLabelDisplay(op)
                                                                : op[customOptionLabelField]
                                                            : op.label
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                            }
                        </ScrollArea.Autosize>
                    </Popover.Dropdown>
                }
            </Popover>
        </div>
    </Input.Wrapper>
}
export default SearchInput
