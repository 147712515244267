export const productListCols: any = [
    {value: 'id', label: 'ID'},
    {value: 'image', label: 'Image'},
    {value: 'name', label: 'Name'},
    {value: 'complete', label: 'Complete'},
    {value: 'status', label: 'Status'},
    {value: 'format', label: 'Format'},
    {value: 'weight', label: 'Weight'},
    {value: 'height', label: 'Height'},
    {value: 'width', label: 'Width'},
    {value: 'depth', label: 'Depth'},
    {value: 'price', label: 'Price', editable: true},
    {value: 'material_gr_lt', label: 'Material gr.(LT)'},
    {value: 'material_gr_es', label: 'Material gr.(ES)'},
    {value: 'aMX', label: 'AMX'},
    {value: 'stock', label: 'Stock', editable:true},
    {value: 'sku', label: 'SKU'},
    {value: 'ean', label: 'EAN'},
    {value: 'bic', label: 'BIC'},
    {value: 'brand', label: 'Brand'},
]
