import {useSanctum} from "react-sanctum";
import {Navigate, Outlet, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {LoadingOverlay} from "@mantine/core";
import {checkPermissions} from "../helpers/helpers";

const RequireAuth = () => {
    const {authenticated, user} = useSanctum()
    const navigate = useNavigate()

    useEffect(() => {
        if (authenticated === false) {
            navigate('/login', {})
        }
    }, [authenticated])

    return <>
        {
            authenticated === null || (!user || !user.id)
                ? <LoadingOverlay visible={true} overlayOpacity={1}/>
                : <Outlet/>
        }
    </>
}


const Authenticated = () => {
    const {authenticated} = useSanctum()

    if (authenticated) return <Navigate to={'/dashboard'} replace={true}/>
    return <Outlet/>
}

const HasPermission = ({user, requiredPermissions = []}) => {
    if (!user || !user.id) return <Navigate to={'dashboard'} replace/>
    else if (checkPermissions(requiredPermissions)) return <Outlet/>
    else return <Navigate to={'dashboard'} replace/>
}


export {
    RequireAuth,
    Authenticated,
    HasPermission
}
