import {createStyles, Stack, TextInput, Button, Select, ActionIcon, Table} from "@mantine/core";
import {useEffect, useState} from "react";
import {Lock, Pencil, Plus, Trash} from "tabler-icons-react";
import NavbarExtension from "../../components/layout/NavbarExtension";
import {useNavbarExtension} from "../../customHooks/hooks";
import CategoriesListExtension from "./subPages/CategoriesListExtension";
import {Link, useLocation} from "react-router-dom";
import PricingRuleModal from "../../components/PricingRuleModal";
import {deletePricingRule, getSupplierCategories, getSuppliers, updateCategory} from "../../backend/axios";
import {openConfirmModal} from "@mantine/modals";
import {showNotification} from "@mantine/notifications";

const CategoriesList = () => {
    const navbarExtension = useNavbarExtension()
    const {classes} = useStyles()
    const location = useLocation()
    const [selected, setSelected] = useState(undefined)
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [formLoading, setFormLoading] = useState(false)

    const [openNewRule, setOpenNewRule] = useState(false)
    const [supplier, setSupplier] = useState('')
    const [catList, setCatList]: any = useState({})
    const [suppliers, setSuppliers] = useState([])

    const getData = (e = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        getSuppliers()
            .then(r => {
                if (r && r.data) setSuppliers(r.data.map(item => {
                    return {value: item.id, label: item.name}
                }))
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getData()
        if (location && location.state && location.state.filter && location.state.filter.supplier) onSupplierChange(location.state.filter.supplier)
    }, [])

    useEffect(() => {
        if (supplier) getSupplierCatData()
    }, [supplier])

    const getSupplierCatData = () => {
        setLoading(true)
        getSupplierCategories({supplier: supplier})
            .then(r => {
                if (r && r.data) setCatList(r.data)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const onSupplierChange = (op) => {
        setSupplier(op)
        setSelected('')
    }

    const onDataChange = (values) => {
        let _errors = {...errors}
        Object.keys(values).map(key => {
            delete _errors[key]
        })
        setErrors(_errors)
        setSelected({...selected, ...values})
    }

    const onSaveChanges = (e) => {
        if (e) e.preventDefault()
        setFormLoading(true)
        updateCategory(selected.id, selected)
            .then(r => {
                if (r && r.data) {
                    getSupplierCatData()
                }
                setFormLoading(false)
            })
            .catch(() => {
                setFormLoading(false)
            })
    }

    const onDeleteRule = (item) => {
        openConfirmModal({
            title: `Confirm rule delete`,
            labels: {confirm: 'Delete', cancel: 'Cancel'},
            centered: true,
            confirmProps: {
                compact: true,
                variant: 'white', size: 'xs'
            },
            cancelProps: {
                compact: true,
                variant: 'filled', size: 'xs'
            },
            onConfirm: () => onDelete(item),
        })
    }

    const onDelete = (item) => {
        if (item && item.pricing_rule && item.pricing_rule.id) deletePricingRule(item.pricing_rule.id)
            .then(r => {
                if (r && r.status === 200) {
                    let _data = {...selected, pricing_rule: null}
                    setSelected(_data)
                    getSupplierCatData()
                    showNotification({color: 'red', message: 'Rule deleted'})
                }
            })
            .catch(e => {
                if (e && e.response && e.response.status) showNotification({
                    color: 'red',
                    message: `Error ${e.response.status}`
                })
            })
    }

    const onCreate = (item) => {
        let _data = {...selected}
        _data.pricing_rule = item
        setSelected(_data)
        getSupplierCatData()
        setOpenNewRule(false)
    }

    const onSave = (item) => {
        let _data = {...selected}
        _data.pricing_rule = item
        setSelected(_data)
        getSupplierCatData()
        setOpenNewRule(false)
    }


    const onSelectEdit = () => {
        setOpenNewRule(true)
    }

    return <>
        <NavbarExtension {...navbarExtension} open={true} width={400}>
            <CategoriesListExtension suppliers={suppliers}
                                     supplier={supplier}
                                     loading={loading}
                                     catList={catList}
                                     onSupplierChange={onSupplierChange}
                                     setSelected={setSelected}
                                     selected={selected}/>
        </NavbarExtension>

        {
            openNewRule && <PricingRuleModal open={openNewRule}
                                             setOpen={setOpenNewRule}
                                             onSave={onSave}
                                             onCreate={onCreate}
                                             type={'category'}
                                             selected={
                                                 {
                                                     category: {id: selected.id},
                                                     margin: selected.pricing_rule ? selected.pricing_rule.margin : '',
                                                     discount: selected.pricing_rule ? selected.pricing_rule.discount : ''
                                                 }
                                             }/>
        }


        <section className={'main'}>
            {
                !supplier ?
                    <h2 style={{fontWeight: 500}}>Choose supplier</h2>
                    : <form onSubmit={onSaveChanges}>
                        {selected && selected.id &&
                            <span style={{
                                fontSize: 11,
                                color: '#989898'
                            }}>Show selected category  <Link
                                to={'/products'}
                                state={{
                                    filter: {
                                        supplier: supplier,
                                        category: selected
                                    }
                                }}>products</Link>
                            </span>
                        }
                        <h5 className={'muted lean-text'}>Category</h5>
                        {
                            selected && <>
                                <section className={classes.product_section}>
                                    <div className={'product__section_name'}>
                                        GENERAL PROPERTIES
                                    </div>

                                    <div className={'product__section_content'}>
                                        <Stack>
                                            <TextInput label={"Code (required)"} required disabled
                                                       rightSection={<Lock size={18} strokeWidth={1.2} color={'gray'}/>}/>
                                            <TextInput label={"BIC"}
                                                       value={selected.bic ? selected.bic : ''}
                                                       disabled={formLoading}
                                                       onChange={(e) => onDataChange({bic: e.target.value})}/>
                                        </Stack>
                                    </div>
                                </section>


                                <section className={classes.product_section}>
                                    <div className={'product__section_name'}>NODE VALUES</div>

                                    <div className={'product__section_content'}>
                                        <Stack>
                                            <TextInput label={"Name"}
                                                       value={selected.name}
                                                       disabled={formLoading}
                                                       onChange={(e) => onDataChange({name: e.target.value})}/>
                                            <Select label={'Status'}
                                                    disabled={formLoading}
                                                    data={[
                                                        {value: true, label: 'Enabled'},
                                                        {value: false, label: 'Disabled'}
                                                    ]}
                                                    value={!!selected.status} onChange={(op) => onDataChange({status: op})}/>
                                        </Stack>

                                    </div>
                                </section>

                                <section className={classes.product_section}>
                                    <div className={'product__section_name'}>
                                        <div>Pricing rule</div>
                                        <ActionIcon size={'sm'} color={'brand'} onClick={() => setOpenNewRule(true)}>
                                            <Plus strokeWidth={1.6} size={17}/>
                                        </ActionIcon>
                                    </div>

                                    {
                                        selected.pricing_rule && selected.pricing_rule.id &&
                                        <div className={'product__section_content'}>
                                            <Table className={'ruleListTable'}>
                                                <thead>
                                                <tr>
                                                    <th>Discount</th>
                                                    <th>Margin</th>
                                                    <th style={{width: '0'}}></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{`${selected.pricing_rule.discount} %`}</td>
                                                    <td>{`${selected.pricing_rule.margin} %`}</td>
                                                    <td>
                                                        <div style={{display: 'flex', gap: 5}}>
                                                            <ActionIcon size={22} color={'red.6'}
                                                                        onClick={() => onDeleteRule(selected)}>
                                                                <Trash size={25} strokeWidth={1.2}/>
                                                            </ActionIcon>
                                                            <ActionIcon size={22} color={'gray.6'}
                                                                        onClick={() => onSelectEdit()}>
                                                                <Pencil size={25} strokeWidth={1.2}/>
                                                            </ActionIcon>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                </section>

                                <div style={{marginTop: 30}}>
                                    <Button size={'sm'} compact type={'submit'} loading={formLoading}>Save</Button>
                                </div>
                            </>
                        }
                    </form>
            }
        </section>
    </>
}
export default CategoriesList

const useStyles = createStyles((theme) => ({
    header: {
        display: 'flex',
        gap: 15,
        flexWrap: 'wrap',
        marginLeft: 10
    },
    header_label: {
        fontSize: 12,
        fontWeight: 400,
        color: 'rgb(126 129 135)'
    },
    header_name: {
        color: theme.colors.brand[6],
        fontSize: '1.2rem'
    },
    product_section: {
        marginTop: 20,
        '.product__section_name': {
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            paddingBottom: 5,
            marginBottom: 5,
            textTransform: 'uppercase',
            fontSize: '0.75rem',
            fontWeight: 500,
            borderBottom: '1px solid #e0e0e0'
        },
        '.product__section_content': {
            maxWidth: 500
        }
    },
    imageBorder: {
        border: '1px solid #ebebeb',
        padding: 3,
        width: "max-content",
        height: 'max-content'
    }
}));
