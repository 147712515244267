import {useBaseObjectForm} from "../customHooks/hooks";
import {ActionIcon, Badge, TextInput} from "@mantine/core";
import {DeviceFloppy, Refresh} from "tabler-icons-react";
import {Link} from "react-router-dom";
// @ts-ignore
import plaImage from '../../images/500.png'
import {updateProduct} from "../backend/axios";
import {getErrorMessage, getResponseErrors} from "../helpers/helpers";

const ProductTableRow = ({index, item, activeColumns, productListOptions, onSaveChanges, filter}) => {

    const {data, onDataChange, errors, setErrors, isChanged, setData} = useBaseObjectForm(item)

    const onSaveData = (item) => {
        if (item.id) updateProduct(item.id, data).then(r => {
            if (onSaveChanges && r && r.data) {
                onSaveChanges(r.data, index)
                setData(r.data)
            }
        }).catch(e => {
            setErrors(getResponseErrors(e))
        })
    }

    return <tr key={`row-${index}`}>
        <td style={{width: 80}}>
            {
                isChanged && <>
                    <ActionIcon size={'sm'} onClick={() => onSaveData(item)}><DeviceFloppy/></ActionIcon>
                    <ActionIcon size={'sm'} onClick={() => onDataChange(item)}><Refresh/></ActionIcon>
                </>
            }
        </td>

        {
            productListOptions.map((item, item_index) => {
                if (activeColumns.includes(item.value)) {
                    let col = item.value
                    switch (col) {
                        case 'id' : {
                            return <td style={{width: 80}} key={`col-${item_index}`}>{data[col]}</td>
                        }
                        case 'image' : {
                            return <td key={`col-${item_index}`}>
                                <div style={{
                                    border: '1px solid #ebebeb',
                                    padding: 3,
                                    width: "max-content",
                                    height: 'max-content',
                                    objectFit: 'contain'
                                }}>
                                    {
                                        data.images && data.images.length > 0
                                            ? <img src={data.images[0]} alt={'missing'}
                                                   width={70}
                                                   height={70}/>
                                            : <img src={plaImage} alt={'missing'}
                                                   width={70}
                                                   height={70}/>
                                    }
                                </div>
                            </td>
                        }
                        case 'name' : {
                            return <td key={`col-${item_index}`}>{data[col] ?
                                <Link to={`/products/${data.id}`} state={{filter: filter}}>{data[col]}</Link> : ''}
                            </td>
                        }
                        case 'status' : {
                            return <td key={`col-${item_index}`}><Badge variant={'filled'}
                                                                        size={"xs"}
                                                                        styles={{root: {fontWeight: 500}}}
                                                                        color={data[col] ? 'green.6' : 'gray.5'}>
                                {data['status_text'] ? data['status_text'] : data[col]}
                            </Badge>
                            </td>
                        }
                        case 'complete' : {
                            return <td key={`col-${item_index}`}>{data[col]
                                ? <Badge variant={'dot'}
                                         styles={{root: {fontWeight: 500}}}
                                         color={data[col] === 100 ? 'green.8' : 'yellow.8'}>
                                    {data[col] ? `${data[col]}%` : ''}
                                </Badge>
                                : ''
                            }
                            </td>
                        }
                        default: {
                            let _render_col = productListOptions.find(prlc => prlc.value === col)
                            if (_render_col) {
                                return <td key={`col-${item_index}`}>
                                    {
                                        _render_col && _render_col.editable
                                            ? <TextInput value={data[col] ? data[col] : ''}
                                                         errors={getErrorMessage(errors, data[col])}
                                                         onChange={(e) => onDataChange({[col]: e.target.value})}
                                                         style={{maxWidth: 250}}/>
                                            : data[col]
                                    }
                                </td>
                            }
                        }
                    }
                }
            })
        }
    </tr>
}

export default ProductTableRow
