import {Table, TextInput, ActionIcon, Button, Pagination, LoadingOverlay} from "@mantine/core";
import {useEffect, useState} from "react";
import {Pencil, Search, Trash} from "tabler-icons-react";
import PricingRuleModal from "../../components/PricingRuleModal";
import FullPricingRuleModal from "../../components/FullPricingRuleModal";
import {openConfirmModal} from "@mantine/modals";
import {deletePricingRule, getPricingRules} from "../../backend/axios";
import {showNotification} from "@mantine/notifications";
import {Link} from "react-router-dom";
import {checkPermissions} from "../../helpers/helpers";

const SupplierList = () => {

    const [data, setData] = useState([])
    const [openPricingModal, setOpenPricingModal] = useState(false)
    const [selected, setSelected]: any = useState('')
    const [openFullPricingModal, setOpenFullPricingModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [meta, setMeta]: any = useState()
    const [currentPage, setCurrentPage] = useState(1)

    const onSelectEdit = (item) => {
        setSelected(item)
        setOpenPricingModal(true)
    }

    const onDeleteRule = (item) => {
        openConfirmModal({
            title: `Confirm rule delete ${item.id}`,
            labels: {confirm: 'Delete', cancel: 'Cancel'},
            centered: true,
            confirmProps: {
                compact: true,
                variant: 'white'
            },
            cancelProps: {
                compact: true,
                variant: 'filled'
            },
            onConfirm: () => onDelete(item),
        })
    }

    const onDelete = (item) => {
        deletePricingRule(item.id)
            .then(r => {
                if (r && r.status === 200) {
                    let _data = [...data]
                    _data.splice(item.listIndex, 1)
                    setData(_data)
                    showNotification({color: 'red', message: 'Rule deleted'})
                }
            })
            .catch(e => {
                if (e && e.response && e.response.status) showNotification({
                    color: 'red',
                    message: `Error ${e.response.status}`
                })
            })
    }

    const getData = (e = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        // setData([])
        getPricingRules({page: currentPage})
            .then(r => {
                if (r && r.data && r.data.data) {
                    setData(r.data.data)
                    if (r.data.meta) setMeta(r.data.meta)
                }
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setSelected('')
        getData()
    }, [currentPage])

    const onSaveRule = (item) => {
        if (item && item.id) {
            let _data = [...data]
            _data[item.listIndex] = item
            setData(_data)
            showNotification({message: 'Rule updated', color: 'green'})
            setOpenPricingModal(false)
        }
    }

    const onCreate = (item) => {
        if (item && item.id) {
            let _data = [...data]
            _data.push(item)
            setData(_data)
            showNotification({message: 'Rule updated', color: 'green'})
            setOpenFullPricingModal(false)
        }
    }

    return <>
        {
            openPricingModal &&
            <PricingRuleModal open={openPricingModal}
                              setOpen={setOpenPricingModal}
                              onSave={onSaveRule}
                              selected={selected}/>
        }
        {
            openFullPricingModal &&
            <FullPricingRuleModal open={openFullPricingModal}
                                  setOpen={setOpenFullPricingModal}
                                  onCreate={onCreate}
                                  selected={selected}/>
        }
        <section className={'main'}>

            <h5 className={'muted lean-text'}>Pricing rule</h5>

            <TextInput placeholder={'Search for supplier code, supplier name, category, product'}
                       variant={"unstyled"}
                       styles={{root: {borderBottom: "1px solid #e8e9ec"}}}
                       icon={<Search size={20}
                                     strokeWidth={1.2}/>}/>

            {
                checkPermissions(['create pricing-rules']) &&
                <Button compact size={'xs'} style={{marginTop: 25}} onClick={() => setOpenFullPricingModal(true)}>
                    New rule
                </Button>
            }
            <div style={{overflow: 'auto', position: 'relative', marginTop: 10, minHeight: loading ? 150 : 0}}>
                <LoadingOverlay visible={loading}/>
                <Table fontSize={'sm'} highlightOnHover className={'ruleListTable'}>
                    <thead>
                    <tr>
                        <th style={{width: '0'}}></th>
                        <th>Supplier code</th>
                        <th>Supplier name</th>
                        <th>Category</th>
                        <th>Product</th>
                        {/*<th>Didmeninė kaina</th>*/}
                        <th>Discount</th>
                        {/*<th>Savikaina</th>*/}
                        <th>Margin</th>
                        {/*<th>Bazinė lentynos kaina</th>*/}
                        <th style={{width: '0'}}></th>
                    </tr>
                    </thead>

                    {
                        data && data.length > 0 && <tbody style={{position: 'relative'}}>
                        {
                            data.map((item, index) => <tr key={`margin-index-${index}`}
                                                          style={selected && selected.id && selected.id === item.id ? {background: '#fff7c9'} : {}}>
                                    <td>
                                        <div style={{display: 'flex', gap: 5}}>
                                            <ActionIcon size={22} color={'red.6'}
                                                        onClick={() => onDeleteRule({...item, listIndex: index})}>
                                                <Trash size={25} strokeWidth={1.2}/>
                                            </ActionIcon>
                                            <ActionIcon size={22} color={'gray.6'}
                                                        onClick={() => onSelectEdit({...item, listIndex: index})}>
                                                <Pencil size={25} strokeWidth={1.2}/>
                                            </ActionIcon>

                                        </div>
                                    </td>
                                    <td>{item.supplier && item.supplier.id ? item.supplier.id : ''}</td>
                                    <td>{item.supplier && item.supplier.name ? item.supplier.name : ''}</td>
                                    <td>{item.category && item.category.name ? item.category.name : ''}</td>

                                    {item.product && item.product.id ?
                                        <td><Link to={`/products/${item.product.id}`}>{item.product.name}</Link></td>
                                        :
                                        <td>{item.product && item.product.name ? item.product.name : ''}</td>
                                    }

                                    {/*<td>{item.wholesale_price ? item.wholesale_price : ''}</td>*/}
                                    <td>{item.discount ? `${item.discount}%` : ''}</td>
                                    {/*<td>{item.prime ? item.prime : ''}</td>*/}
                                    <td>{item.margin ? `${item.margin}%` : ''}</td>
                                    {/*<td>{item.base_price ? item.base_price : ''}</td>*/}
                                    <td>
                                        <div style={{display: 'flex', gap: 5}}>
                                            <ActionIcon size={22} color={'red.6'}
                                                        onClick={() => onDeleteRule({...item, listIndex: index})}>
                                                <Trash size={25} strokeWidth={1.2}/>
                                            </ActionIcon>
                                            <ActionIcon size={22} color={'gray.6'}
                                                        onClick={() => onSelectEdit({...item, listIndex: index})}>
                                                <Pencil size={25} strokeWidth={1.2}/>
                                            </ActionIcon>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    }
                </Table>
            </div>
            {
                meta &&
                <div style={{display: 'flex', margin: '15px auto', width: '100%', justifyContent: 'center'}}>
                    <Pagination size={'sm'}
                                radius={12}
                                page={currentPage}
                                onChange={(page) => setCurrentPage(page)}
                                total={meta ? meta.last_page : 0}/>
                </div>
            }
        </section>
    </>
}
export default SupplierList
