import {useEffect, useState} from "react";
import {getSuppliers} from "../backend/axios";

export const useBaseObjectForm = (preDefinedValues = undefined) => {

    const [data, setData] = useState(preDefinedValues ? preDefinedValues : {})
    const [errors, setErrors] = useState({})
    const [isChanged, setIsChanged] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setIsChanged(data && preDefinedValues && JSON.stringify(data) !== JSON.stringify(preDefinedValues))
    }, [data, preDefinedValues])

    const onDataChange = (values) => {
        let _errors = {...errors}
        Object.keys(values).map(key => {
            delete _errors[key]
        })
        setErrors(_errors)

        setData({...data, ...values})
    }

    return {data, setData, errors, setErrors, onDataChange, isChanged, setIsChanged, loading, setLoading}
}

export const useBaseAxiosCancel = () => {

    const [axiosToken, setAxiosToken]: any = useState();
    const [searchTimeout, setSearchTimeout]: any = useState();

    return {axiosToken, setAxiosToken, searchTimeout, setSearchTimeout}
}


export const useNavbarExtension = () => {

    const [open, setOpen] = useState(false)
    const [content, setContent] = useState(<></>)

    useEffect(() => {
        if (content && open) {
            let nav = document.querySelector('nav')
            if (nav) nav.appendChild(document.createElement('div'));
        }
    }, [open])

    return {open, setOpen, content, setContent}
}


export const getSupplierOptions = ({setOptions = undefined, setLoading = undefined}) => {

    if (setLoading) setLoading(true)
    getSuppliers()
        .then(r => {
            if (r && r.data) {
                if (r.data && setOptions) setOptions(r.data.map(item => {
                    return {value: `${item.id}`, label: item.name}
                }))
            }
            setLoading(false)
        })
        .catch(() => {
            setLoading(false)
        })
}
