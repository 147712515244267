import {Badge, createStyles, Divider, LoadingOverlay, Stack, UnstyledButton} from "@mantine/core";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import SupplierTemplateForm from "./subPages/SupplierTemplateForm";
import SuppliersPricingRulesList from "./subPages/SuppliersPricingRulesList";
import {openConfirmModal} from "@mantine/modals";
import {getSupplier} from "../../backend/axios";

const SupplierShow = () => {
    const {classes} = useStyles()
    const params = useParams()

    const [data, setData]: any = useState({})
    const [loading, setLoading] = useState(false)

    const changeStatus = () => {
        openConfirmModal({
            title: `Change status to: ${data.status === 'active' ? 'Disabled' : "Active"}`,
            children: (<div/>),
            confirmProps: {
                compact: true,
                variant: 'white'
            },
            cancelProps: {
                compact: true,
                variant: 'filled'
            },
            centered: true,
            labels: {confirm: 'Confirm', cancel: 'Cancel'},
            onConfirm: () => console.log('Confirmed'),
        });
    }

    const getData = (e = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        getSupplier(params.id)
            .then(r => {
                if (r && r.data) setData(r.data)

                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [])

    return <>
        <section className={'main'} style={{position: 'relative'}}>
            <LoadingOverlay visible={loading}/>
            <div className={classes.header}>
                <div>
                    <label className={classes.header_label}>{`Suppliers / ${params.id}`}</label>
                    <div className={classes.header_name}>{data.name ? data.name : ''}</div>
                    {
                        data.status && <UnstyledButton onClick={() => changeStatus()}>
                            <Badge color={data.status ? 'green.8' : 'gray.5'}
                                   variant={data.status ? 'outline' : "filled"}
                                   styles={{root: {fontWeight: 600, padding: '3px 25px'}}}>
                                {data.status_text}
                            </Badge>
                        </UnstyledButton>
                    }
                </div>
            </div>

            <div className={classes.nav_content}>
                <div className={classes.navigation_wrapper}>
                    <label className={classes.static_text}>Navigation: </label>
                    <Link className={classes.link}
                          to={'/categories'} state={{filter: {supplier: params.id}}}>Categories</Link>
                    <Link className={classes.link}
                          to={'/products'} state={{filter: {supplier: params.id}}}>Products</Link>
                </div>
            </div>

            <Stack mt={15}>
                <SuppliersPricingRulesList data={data}
                                           setData={setData}
                                           refreshSupplier={getData}
                                           classes={classes}/>

                <Divider/>

                <SupplierTemplateForm classes={classes}/>
            </Stack>
        </section>
    </>
}
export default SupplierShow


const useStyles = createStyles((theme) => ({
    header: {
        display: 'flex',
        gap: 15,
        flexWrap: 'wrap',
        paddingLeft: 10,
        paddingBottom: 10
    },
    header_label: {
        fontSize: 12,
        fontWeight: 400,
        color: 'rgb(126 129 135)'
    },
    header_name: {
        color: theme.colors.brand[6],
        fontSize: '1.2rem'
    },
    nav_content: {
        padding: 5,
        borderBottom: '1px solid #eee',
        borderTop: '1px solid #eee',
    },
    navigation_wrapper: {
        display: 'flex',
        gap: 15
    },
    static_text: {
        color: 'rgb(163,166,175)',
        fontSize: '0.75rem',
        fontWeight: 500,
    },
    link: {
        color: 'rgb(163,166,175)',
        fontSize: '0.75rem',
        fontWeight: 500,
        cursor: 'pointer',
        ':hover': {
            color: theme.colors.brand[6],
            transition: '0.15s'
        }
    },
    content_header: {
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 15
    }
}));
