import {Badge, Table, TextInput, ActionIcon, UnstyledButton, LoadingOverlay} from "@mantine/core";
import {useEffect, useState} from "react";
import {Pencil, Search} from "tabler-icons-react";
import {Link, useNavigate} from "react-router-dom";
import {supplierListMock} from "../../mockData";
import {getProducts, getSuppliers} from "../../backend/axios";

const SupplierList = () => {
    const navigate = useNavigate()
    const [data, setData] = useState(supplierListMock)
    const [loading, setLoading] = useState(false)

    const [filter, setFilter]: any = useState({
        query: '',
    })

    const getData = (e = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        setData([])
        getSuppliers({...filter})
            .then(r => {
                if (r && r.data) setData(r.data)

                setLoading(false)
            })
            .catch(e => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [filter])

    return <section className={'main'}>

        <h5 className={'muted lean-text'}>Suppliers</h5>

        <TextInput placeholder={'Search for name or id'}
                   variant={"unstyled"}
                   value={filter.query}
                   onChange={(e) => setFilter({...filter, query: e.target.value})}
                   styles={{root: {borderBottom: "1px solid #e8e9ec"}}}
                   icon={<Search size={20}
                                 strokeWidth={1.2}/>}/>

        <div style={{overflow: 'auto', marginTop: 25, position: 'relative', minHeight: loading ? 150 : 0}}>
            <LoadingOverlay visible={loading}/>
            <Table highlightOnHover className={'productListTable'}>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th style={{width: 50}}></th>
                </tr>
                </thead>

                {
                    data && data.length > 0 && <tbody>
                    {
                        data.map((item, index) => <tr key={`supplier-${index}`}>
                                <td>{item.id ? item.id : ''}</td>
                                <td>{item.name ? <Link to={`/suppliers/${item.id}`}>{item.name}</Link> : ''}</td>
                                <td>{item.status
                                    ? <UnstyledButton>
                                        <Badge color={item.status ? 'green.8' : 'gray.5'}
                                               variant={item.status ? 'outline' : "filled"}
                                               styles={{root: {fontWeight: 600}}}>
                                            {item.status_text}
                                        </Badge>
                                    </UnstyledButton>
                                    : ''}
                                </td>
                                <td>
                                    <ActionIcon onClick={() => navigate(`/suppliers/${item.id}`)}>
                                        <Pencil strokeWidth={1} size={20}/>
                                    </ActionIcon>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                }
            </Table>
        </div>
    </section>
}
export default SupplierList
