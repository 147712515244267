import {checkPermissions} from "../../helpers/helpers";
import RoleSettings from "./RoleSettings";

const Settings = () => {

    return <section className={'main'}>
        <h5 className={'muted lean-text'}>Settings</h5>

        {checkPermissions(['view roles']) && <RoleSettings/>}

    </section>
}
export default Settings
