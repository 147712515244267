import axios from "axios";

//TODO Add sentry
const API = axios.create({
    baseURL:  (!process.env.NODE_ENV) ? 'http://localhost:8000/api' : 'https://pegasas-api.avaro.dev/api',
    withCredentials: true,
    headers: {
        common: {
            'Accept': 'application/json'
        }
    },
});
API.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status >= 500) {
        // captureException(error)
    }
    if (error.response.status === 401 || error.response.status === 419) {
        redirect()
        // captureException(error)
    }

    return Promise.reject(error)
})

const redirect = () => {
    if (!(
        excludedRedirectRoutes.includes(window.location.pathname)
        || window.location.pathname.match(/forgot-password\/.*/)
        || window.location.pathname.match(/new-password\/.*/)
        || window.location.pathname.match(/register\/.*/)
    )) {
        window.location.href = '/login'
    }

}

const excludedRedirectRoutes = [
    '/forgot', '/new-password', '/login', '/register',
]

export {API}
