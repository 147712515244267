import {useState} from "react";
import {createStyles, List, ActionIcon} from "@mantine/core";
import {ChevronDown, ChevronUp, Folder} from "tabler-icons-react";

const NestedList = ({
                        nest,
                        allowHighlight = false,
                        selected = '',
                        allowCreate = false,
                        setSelected = () => {
                        }
                    }: any) => {
    const {classes} = useStyles()
    const [open, setOpen] = useState(true)


    const toggleChevron = () => {
        if (nest.nest && nest.nest.length > 0) setOpen(!open)
    }

    return nest && <List withPadding
                         classNames={{itemWrapper: classes.itemWrapper}}
                         icon={<></>}>

        <List.Item className={classes.listItem} onClick={() => {
            if (setSelected) setSelected(nest)
        }}>
            <div className={classes.nestItemWrapper}>
                {nest.nest ?
                    open
                        ? <ActionIcon radius={0} size={"md"} onClick={() => toggleChevron()}>
                            <ChevronUp strokeWidth={1} size={20}/>
                        </ActionIcon>
                        : <ActionIcon radius={0} size={"md"} onClick={() => toggleChevron()}>
                            <ChevronDown strokeWidth={1} size={20}/>
                        </ActionIcon>
                    : <Folder strokeWidth={1} size={20}/>}

                <div
                     style={{
                         color: !nest.status ? '#a9a9a9' : "#000",
                         fontWeight: allowHighlight && (selected === nest.id || (selected && selected.id === nest.id)) ? 700 : 400
                     }}>
                    <div style={{width: '100%'}}>
                        {nest.name}
                    </div>
                </div>
            </div>
        </List.Item>

        {
            nest && nest.children && nest.children.length > 0 && nest.children.map((li, li_i) => {
                return <NestedList key={`${li_i}-in`}
                                   nest={li}
                                   selected={selected}
                                   allowCreate={allowCreate}
                                   allowHighlight={allowHighlight}
                                   setSelected={setSelected}/>
            })
        }
    </List>
}
export default NestedList

const useStyles = createStyles((theme) => ({
    itemWrapper: {
        width: '100%',
        alignItems: 'center !important',
        '.row-action': {
            display: 'none'
        },
        ':hover': {
            '.row-action': {
                display: 'block'
            },
            background: '#eaeaea'
        }
    },
    listItem: {
        fontSize: 14, alignItems: 'center', width: "100%",
        minHeight: 28,
    },
    nestItemWrapper: {
        minHeight: 28,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        cursor: 'pointer',
        alignItems: 'center !important',
        gap:10
    }
}));
