import {
    ActionIcon, Badge, Grid, Input, Modal, MultiSelect,
    Switch, Table, TextInput, UnstyledButton, Button, LoadingOverlay, Stack, PasswordInput
} from "@mantine/core";
import {Pencil, Search} from "tabler-icons-react";
import {useEffect, useState} from "react";
import {useBaseAxiosCancel, useBaseObjectForm} from "../../customHooks/hooks";
import {checkPermissions, getErrorMessage} from "../../helpers/helpers";
import {getUsers, storeUser, updateUser} from "../../backend/axios";
import axios from "axios";
import {API} from "../../backend/api";

const UserList = () => {
    const [data, setData] = useState([])
    const [filter, setFilter] = useState({query: '', roles: []})
    const [openForm, setOpenForm] = useState(false)
    const [selected, setSelected]: any = useState({name: '', status: false, id: '', roles: []})
    const [loading, setLoading] = useState(false)

    const {axiosToken, setAxiosToken, searchTimeout, setSearchTimeout}: any = useBaseAxiosCancel()
    const [roleOptions, setRoleOptions] = useState([])

    const onSave = (item) => {
        if (item && item.id) {
            let _data = [...data]
            _data[selected.listIndex] = item
            setData(_data)
        }
    }

    const onCreate = (item) => {
        if (item && item.id) {
            let _data = [...data]
            _data.push(item)
            setData(_data)
        }
    }

    useEffect(() => {
        getData()
        API.get('/users').then()
        API.get('/roles').then(r => {
            if (r && r.data) setRoleOptions(r.data)
        })
    }, [filter])


    const getData = () => {
        setLoading(true);
        if (searchTimeout) clearTimeout(searchTimeout);
        if (axiosToken !== undefined) axiosToken.cancel();

        let _token = axios.CancelToken.source();
        setAxiosToken(_token);
        setData([])
        setSearchTimeout(
            setTimeout(() => {
                getUsers(filter, _token.token)
                    .then(r => {
                        if (r && r.data) setData(r.data)
                        setLoading(false)
                    })
                    .catch((error) => {
                        if (axios.isCancel(error)) {
                        } else setLoading(false)
                    })
            }, 500)
        )
    }

    useEffect(() => {
        return () => {
            if (axiosToken !== undefined) axiosToken.cancel();
        };
    }, [axiosToken])


    return <>
        {
            openForm && <UserForm opened={openForm}
                                  setOpen={setOpenForm}
                                  selected={selected}
                                  onSave={onSave}
                                  onCreate={onCreate}
                                  roleOptions={roleOptions}/>
        }
        <section className={'main'}>
            <h5 className={'muted lean-text'}>Users</h5>

            {
                checkPermissions(['create users']) && <div style={{display: 'flex', gap: 10}}>
                    <Button compact size={'xs'} variant={'filled'} onClick={() => {
                        setOpenForm(true)
                        setSelected(null)
                    }}> Create user </Button>
                </div>
            }

            <Grid align={'flex-end'} p={0} mt={15}>
                <Grid.Col lg={8} md={8} sm={12}>
                    <TextInput placeholder={'Search for name or id'}
                               variant={"unstyled"}
                               styles={{root: {borderBottom: "1px solid #e8e9ec"}}}
                               icon={<Search size={20}
                                             strokeWidth={1.2}/>}
                               value={filter.query}
                               onChange={(e) => setFilter({...filter, query: e.target.value})}/>

                </Grid.Col>
            </Grid>
            <div style={{
                overflow: 'auto',
                marginTop: 25,
                position: "relative",
                minHeight: loading ? 150 : 0
            }}>
                <LoadingOverlay visible={loading} loaderProps={{size: 'md'}} zIndex={5}/>
                <Table fontSize={'sm'} highlightOnHover className={'productListTable'}
                       style={{tableLayout: 'fixed', minWidth: 800}}>
                    <thead>
                    <tr>
                        <th style={{width: 50}}>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th style={{maxWidth: 500}}>Roles</th>
                        <th style={{width: 50}}></th>
                    </tr>
                    </thead>

                    {
                        data && data.length > 0 && <tbody>
                        {
                            data.map((item, index) => <tr key={`user-${index}`}>
                                <td>{item.id ? item.id : ''}</td>
                                <td>{item.display_name}</td>
                                <td>{item.email}</td>
                                <td>{item.status
                                    ? <UnstyledButton>
                                        <Badge color={item.status ? 'green.8' : 'gray.5'}
                                               variant={item.status ? 'outline' : "filled"}
                                               styles={{root: {fontWeight: 600}}}>
                                            {item.status_text}
                                        </Badge>
                                    </UnstyledButton>
                                    : ''}
                                </td>
                                <td style={{maxWidth: 500}}>
                                    {
                                        item.roles && item.roles.length > 0 && item.roles.map((role, index_r) =>
                                            <span key={`item_index${index_r}`} style={{marginRight: 5}}>
                                                {role}
                                                {item.roles.length > 0 && index_r + 1 < item.roles.length && <>,</>}
                                                </span>
                                        )
                                    }
                                </td>
                                <td>
                                    <ActionIcon onClick={() => {
                                        setSelected({...item, listIndex: index})
                                        setOpenForm(true)
                                    }}>
                                        <Pencil strokeWidth={1} size={20}/>
                                    </ActionIcon>

                                </td>
                            </tr>)
                        }
                        </tbody>
                    }
                </Table>
            </div>
        </section>
    </>
}

export default UserList

const UserForm = ({
                      opened = false,
                      setOpen = undefined,
                      selected = undefined,
                      onSave = undefined,
                      roleOptions = [],
                      onCreate = undefined
                  }: any) => {

    const {data, errors, setErrors, onDataChange} = useBaseObjectForm(selected ? selected : {
        first_name: '',
        last_name: '',
        email: '',
        status: false,
        roles: [],
        password: ''
    })
    const submit = (e = undefined) => {
        if (e) e.preventDefault()

        if (selected && selected.id) {
            updateUser(data.id, data).then(r => {
                if (r && r.data) {
                    onSave(r.data)
                    setOpen(false)
                }
            }).catch(e => {
                if (e && e.response && e.response.data && e.response.data.errors) setErrors(e.response.data.errors)
            })
        } else {
            storeUser(data).then(r => {
                if (r && r.data) {
                    onCreate(r.data)
                    setOpen(false)
                }
            }).catch(e => {
                if (e && e.response && e.response.data && e.response.data.errors) setErrors(e.response.data.errors)
            })
        }
    }

    return <Modal title={selected && selected.id ? `Edit user ${data.display_name}` : "Create user"}
                  opened={opened}
                  centered
                  onClose={() => setOpen(false)}>
        <form>
            <Stack spacing={5}>
                <TextInput label={'First name'}
                           value={data.first_name}
                           required={!selected}
                           error={getErrorMessage(errors, 'first_name')}
                           onChange={(e) => onDataChange({first_name: e.target.value})}/>

                <TextInput label={'Last name'}
                           value={data.last_name}
                           required={!selected}
                           error={getErrorMessage(errors, 'last_name')}
                           onChange={(e) => onDataChange({last_name: e.target.value})}/>

                <TextInput label={'Email'}
                           value={data.email}
                           required={!selected}
                           error={getErrorMessage(errors, 'email')}
                           onChange={(e) => onDataChange({email: e.target.value})}/>

                {
                    !selected && <PasswordInput label={'Password'}
                                                value={data.password}
                                                required={!selected}
                                                error={getErrorMessage(errors, 'password')}
                                                onChange={(e) => onDataChange({password: e.target.value})}/>
                }

                <Input.Wrapper label={'Status'} error={getErrorMessage(errors, 'status')}>
                    <Switch styles={{body: {display: 'flex'}}}
                            onLabel="ON"
                            offLabel="OFF"
                            size={'md'}
                            checked={data.status === 1}
                            onChange={(event) => onDataChange({status: event.currentTarget.checked === true ? 1 : 0})}/>
                </Input.Wrapper>

                <MultiSelect label={'Roles'}
                             data={roleOptions}
                             error={getErrorMessage(errors, 'roles')}
                             value={data.roles}
                             onChange={(op) => onDataChange({roles: op})}/>
            </Stack>
            <div style={{display: 'flex', gap: 10, justifyContent: 'flex-end', marginTop: 15}}>
                <Button compact size={'xs'} variant={'subtle'} onClick={() => setOpen(false)}>Cancel</Button>
                <Button type={"submit"} compact size={'xs'} onClick={(e) => submit(e)}>Save</Button>
            </div>
        </form>
    </Modal>
}
