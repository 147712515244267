import {createStyles, Select, TextInput} from "@mantine/core";
import {useNavigate} from "react-router-dom";

const linksMock = [
    {value: 'attributes', label: 'Attributes'},
    {value: 'pricing_rule', label: 'Pricing rule'},
    // {value: 'history', label: 'History'},
]

const ProductShowExtension = ({activeTab, setActiveTab, data, onDataChange}) => {
    const {classes} = useStyles()
    const navigate = useNavigate()
    const isActive = (attr) => {
        if (attr && attr === activeTab) return 'navigation__section_link active'
        else return 'navigation__section_link'
    }

    const onSelectTab = (tab) => {
        navigate(window.location.pathname, {state: {tab: tab}})
        setActiveTab(tab)
    }


    return <>
        <div className={classes.header}>
            <div className={'navigation__section_name'}>Navigation</div>

            <div className={'navigation__link_wrapper'}>
                {
                    linksMock && linksMock.map((link, index) =>
                        <div key={`section-i-${index}`}
                             className={isActive(link.value)}
                             onClick={() => onSelectTab(link.value)}>
                            {link.label}
                        </div>)
                }
            </div>
        </div>

        <div className={classes.footer}>
            <div className={'navigation__section_name'}>Product infos</div>

            <Select label={'STATUS'}
                    styles={fieldStyles}
                    value={data.status}
                    data={[
                        {value: 1, label: 'Enabled'},
                        {value: 0, label: 'Disabled'}
                    ]}
                    onChange={(op) => onDataChange({status: op})}
                    variant={'subtle'}
                    placeholder={'- -'}/>

            <TextInput styles={fieldStyles} readOnly
                       variant={'subtle'}
                       label={'CREATED'}
                       value={data.created_at || '--'}
                       onChange={() => {
                       }}/>
            <TextInput styles={fieldStyles} readOnly
                       variant={'subtle'}
                       label={'LAST UPDATED'}
                       value={data.updated_at || '--'}
                       onChange={() => {
                       }}/>
        </div>
    </>
}

export default ProductShowExtension

const fieldStyles = {
    label: {
        fontSize: '0.65rem',
        color: 'rgb(163,166,175)'
    }
}

const useStyles = createStyles((theme) => ({
    header: {
        marginTop: 20,
        '.navigation__section_name': {
            paddingBottom: 5,
            marginBottom: 5,
            textTransform: 'uppercase',
            letterSpacing: 1.1,
            color: 'rgb(163,166,175)',
            fontSize: '0.75rem',
            fontWeight: 500,
            borderBottom: '1px solid #e0e0e0'
        },
        '.navigation__link_wrapper': {
            display: 'flex',
            flexDirection: 'column',
            gap: 5
        },
        '.navigation__section_link': {
            color: 'rgb(163,166,175)',
            fontSize: '0.75rem',
            cursor: 'pointer'
        },
        '.navigation__section_link.active': {
            color: theme.colors.brand[6],
            fontSize: '0.75rem',
            fontWeight: 500,
        }
    },

    footer: {
        marginTop: "auto",
        marginBottom: 20,
        paddingTop: 20,
        borderTop: '1px solid #e0e0e0',
        '.navigation__section_name': {
            paddingBottom: 5,
            marginBottom: 5,
            textTransform: 'uppercase',
            letterSpacing: 1.3,
            color: 'rgb(163,166,175)',
            fontSize: '0.75rem',
            fontWeight: 500,
        }
    },
}));
