import {useEffect, useState} from "react";
import {createRole, deleteRole, getPermissions, getRoles, updateRoles} from "../../backend/axios";
import {ActionIcon, Button, Checkbox, Input, Spoiler, Stack, Table, TextInput} from "@mantine/core";
import {useBaseObjectForm} from "../../customHooks/hooks";
import {checkPermissions, getErrorMessage, getResponseErrors} from "../../helpers/helpers";
import {DeviceFloppy, Pencil, Refresh, Trash, X} from "tabler-icons-react";

const RoleSettings = () => {

    const [roles, setRoles]: any = useState([])
    const [permissionOptions, setPermissionOptions]: any = useState([])
    const [createNR, setCreateNR] = useState(false)

    const getData = () => {
        getRoles().then(r => {
            if (r && r.data) setRoles(r.data)
        })
        getPermissions().then(r => {
            if (r && r.data) setPermissionOptions(r.data)
        })
    }

    const onSaveRoles = (index, item) => {
        let _roles = [...roles]
        _roles[index] = item
        setRoles(_roles)
    }

    useEffect(() => {
        getData()
    }, [])

    const onCreateRole = (item) => {
        let _roles = [...roles]
        _roles.push(item)
        setRoles(_roles)
    }

    const onDeleteRole = (index) => {
        let _data = [...roles]
        _data.splice(index, 1)
        setRoles(_data)
    }

    return <>
        <div style={{maxWidth: 800}}>
            <h3>Roles</h3>
            <div>
                {checkPermissions(['create roles']) &&
                    <Button size={'xs'} compact mb={10} onClick={() => setCreateNR(true)}>Create new</Button>
                }
                <Table style={{tableLayout: 'fixed', border: '1px solid #ddd'}}>
                    <thead style={{background: '#ddd'}}>
                    <tr>
                        <th>Role</th>
                        <th>Permissions</th>
                        <th style={{width: 60}}/>
                    </tr>
                    </thead>

                    {
                        roles && roles.length > 0 &&

                        <tbody>
                        {
                            createNR && <RoleTableRow role={{name: '', permissions: []}}
                                                      onCreateRole={onCreateRole}
                                                      isCreating={createNR}
                                                      setIsCreating={setCreateNR}
                                                      onDelete={onDeleteRole}
                                                      permissionOptions={permissionOptions}
                                                      setCreating={setCreateNR}/>
                        }
                        {roles.map((role, index) => <RoleTableRow key={`role-${index}`}
                                                                  role={role}
                                                                  index={index}
                                                                  onSaveRoles={onSaveRoles}
                                                                  onDelete={onDeleteRole}
                                                                  permissionOptions={permissionOptions}/>)}

                        </tbody>
                    }
                </Table>
            </div>
        </div>
    </>
}

const RoleTableRow = ({
                          index = undefined,
                          role = {},
                          permissionOptions = [],
                          onSaveRoles = undefined,
                          onCreateRole = undefined,
                          isCreating = false,
                          setCreating = undefined,
                          onDelete = undefined,
                          setIsCreating = undefined
                      }: any) => {

    const {data, onDataChange, errors, setErrors, isChanged, setData} = useBaseObjectForm(role)

    useEffect(() => {
        setData(role)
    }, [role])

    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const onSaveSubmit = () => {
        setLoading(true)
        updateRoles(data.id, data)
            .then(r => {
                if (r && r.data && r.status === 200) {
                    setEditing(false)
                    if (onSaveRoles) onSaveRoles(index, r.data)
                    setLoading(false)
                }
            })
            .catch(e => {
                setErrors(getResponseErrors(e))
                setLoading(false)
            })
    }

    const onSelectedPerm = (value, checked) => {
        if (value && data && data.permissions) {
            let _permissions = [...data.permissions]
            if (checked) {
                _permissions.push(value)
                if (value === 'update users') _permissions.push('view roles')
            } else _permissions = _permissions.filter(vl => vl !== value)

            onDataChange({permissions: _permissions})
        }
    }

    const onCreateSubmit = () => {
        setLoading(true)
        createRole(data)
            .then(r => {
                if (r && r.data) {
                    if (setIsCreating) setIsCreating(false)
                    if (onCreateRole) onCreateRole(r.data)
                }
                setLoading(false)
            })
            .catch(e => {
                setErrors(getResponseErrors(e))
                setLoading(false)
            })
    }

    const onSubmitDelete = (item, index) => {
        if (item.id) deleteRole(item.id).then(r => {
            if (r && r.status === 200) {
                onDelete(index)
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    const permissionPicker = () => <Stack spacing={5}>
        {permissionOptions.map((item, index) => <div
            key={`permissions-${index}`}>
            <Checkbox label={item.name}
                      disabled={loading}
                      onChange={(e) => onSelectedPerm(item.name, e.target.checked)}
                      checked={data ? data.permissions.includes(item.name) : false}
            />
        </div>)
        }
    </Stack>

    return <tr key={`role-${index}`} style={{position: 'relative'}}>
        {
            isCreating && checkPermissions(['create roles']) ?
                <>
                    <td style={{fontSize: 13}}>
                        <TextInput value={data.name}
                                   onChange={(e) => onDataChange({name: e.target.value})}
                                   error={getErrorMessage(errors, 'name')}/>
                    </td>

                    <td style={{fontSize: 13}}>
                        <Input.Wrapper error={getErrorMessage(errors, 'permissions')}>
                            {permissionPicker()}
                        </Input.Wrapper>
                    </td>

                    <td>
                        <Stack spacing={5}>
                            <ActionIcon loading={loading} disabled={loading} size={'sm'}
                                        onClick={() => onCreateSubmit()}><DeviceFloppy/></ActionIcon>
                            <ActionIcon size={'sm'} color={'red'}
                                        onClick={() => setCreating(false)}>
                                <X strokeWidth={1.5}/>
                            </ActionIcon>
                        </Stack>
                    </td>
                </>

                : <>
                    <td style={{fontSize: 13}}>
                        {
                            !(checkPermissions(['update roles']) && editing) ?
                                <>{data.name}</>
                                : <TextInput value={data.name}
                                             variant={'filled'}
                                             onChange={(e) => onDataChange({name: e.target.value})}
                                             error={getErrorMessage(errors, 'name')}/>
                        }
                    </td>
                    <td>
                        {role.label !== 'Super Admin' && <Input.Wrapper error={getErrorMessage(errors, 'permissions')}>
                            {
                                checkPermissions(['update roles']) && editing ? <>
                                        {permissionPicker()}
                                    </>
                                    : role.permissions && role.permissions.length > 0 &&
                                    <Spoiler maxHeight={50} showLabel="Show more" hideLabel="Hide" transitionDuration={0}>
                                        {role.permissions.map((item, pr_index) => <div style={{fontSize: 13}}
                                                                                       key={`pr_index-${pr_index}`}> {item}</div>)}
                                    </Spoiler>
                            }
                        </Input.Wrapper>}
                    </td>
                    <td>
                        {role.label !== 'Super Admin' && <>
                            {checkPermissions(['update roles']) && role.label !== 'Super Admin' && (isChanged || editing) ? <>
                                    <ActionIcon loading={loading} disabled={loading} size={'sm'}
                                                onClick={() => onSaveSubmit()}><DeviceFloppy/></ActionIcon>
                                    <ActionIcon disabled={loading} size={'sm'} onClick={() => {
                                        setEditing(false)
                                        setData(role)
                                    }}><Refresh/></ActionIcon>
                                </>
                                : <Stack spacing={5}>
                                    {checkPermissions(['update roles']) &&
                                        <ActionIcon loading={loading} size={'sm'} onClick={() => setEditing(true)}><Pencil/></ActionIcon>
                                    }

                                    {checkPermissions(['delete roles']) &&
                                        <ActionIcon size={'sm'} color={'red'}
                                                    loading={loading}
                                                    onClick={() => {
                                                        setLoading(true)
                                                        onSubmitDelete(role, index)
                                                    }}>
                                            <Trash strokeWidth={1.5}/>
                                        </ActionIcon>
                                    }
                                </Stack>
                            }
                        </>}
                    </td>
                </>
        }
    </tr>
}

export default RoleSettings
