import {createStyles, Skeleton} from "@mantine/core";

const ProductTableLoadingSkeleton = ({columnCount = 0, rowCount = 1}) => {
    const {classes} = useStyles()

    const getCols = () => {
        let _cols = []
        for (let i = 0; i < columnCount; i++) {
            _cols.push(<td key={`col-${i}`}><Skeleton radius={2} height={35} width={'95%'} m={5}/></td>)
        }
        return _cols
    }

    const getRows = () => {
        let _rows = []
        let _col = getCols()
        for (let i = 0; i < rowCount; i++) {
            _rows.push(<tr key={`row-${i}`} className={classes.loading_tr}>
                {_col}
            </tr>)
        }
        return _rows
    }

    return <>{columnCount && columnCount > 0 && rowCount && rowCount > 0 && getRows()}</>

}
export default ProductTableLoadingSkeleton

const useStyles = createStyles((theme) => ({
    card: {
        padding: 20,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.dark[7]
    },
    loading_tr: {
        'td': {
            padding: '0 !important'
        }
    }
}));
