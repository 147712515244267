import {createStyles} from "@mantine/core";

const NavbarExtension = (props) => {
    const {classes} = useStyles()
    const {width, style, children, open, overObjects = false} = props

    return <>
        <div className={classes.navbar_extension}
             style={{
                 width: open ? width ? width : 250 : 0,
                 minWidth: open ? width ? width : 250 : 0,
                 padding: open ? '100px 20px 20px 20px' : 0,
                 borderRight: open ? '1px solid #ccd1d8' : 'none',
                 overflowX: "auto",
                 position: overObjects?'absolute':'',
                 height: '100%',
                 background: '#f2f2f5',
                 ...style
             }}>
            <>{children}</>
        </div>
    </>
}


export default NavbarExtension

const useStyles = createStyles((theme) => ({
    nav_wrapper: {
        display: 'flex',
        position: 'relative',
    },
    navbar_extension: {
        zIndex: 100,
        width: 250,
        background: '#f2f2f5',
        borderRight: '1px solid #ccd1d8',
        padding: '100px 20px 20px 20px',
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
        // position: 'relative',
    },
}));
