import {MultiSelect, Stack, Textarea, TextInput, Button, Divider, Grid} from "@mantine/core";
import {upperFirst} from "@mantine/hooks";
import {getErrorMessage} from "../../../helpers/helpers";

const ProductAttributes = ({classes, data, onDataChange, onSaveAttributes, errors}: any) => {
    return <form onSubmit={onSaveAttributes}>
        <section className={classes.product_section}>
            <Button compact type={"submit"}>Save</Button>
        </section>
        <section className={classes.product_section}>
            <div className={'product__section_name'}>Size</div>

            <div className={'product__section_content'}>
                <Stack spacing={5}>
                    <TextInput label={'Format'}
                               value={data.format || ''}
                               error={getErrorMessage(errors, 'format')}
                               onChange={(e) => onDataChange({format: e.target.value})}/>

                    <TextInput label={'BIC'}
                               value={data.bic || ''}
                               error={getErrorMessage(errors, 'bic')}
                               onChange={(e) => onDataChange({bic: e.target.value})}/>

                    <TextInput label={'Weight'}
                               placeholder={''}
                               value={data.weight || ''}
                               error={getErrorMessage(errors, 'weight')}
                               onChange={(e) => onDataChange({weight: e.target.value})}
                               radius={0}/>

                    <TextInput label={'Width'}
                               radius={0}
                               error={getErrorMessage(errors, 'width')}
                               value={data.width || ''}
                               onChange={(e) => onDataChange({width: e.target.value})}/>

                    <TextInput label={'Height'}
                               radius={0}
                               error={getErrorMessage(errors, 'height')}
                               value={data.height || ''}
                               onChange={(e) => onDataChange({height: e.target.value})}/>
                </Stack>
            </div>
        </section>

        <section className={classes.product_section}>
            <div className={'product__section_name'}>Kaina ir Mokesčiai</div>

            {data.applied_pricing_rule_type && <>
                <div className={'product__section_content'}>
                    <Grid>
                        <Grid.Col lg={6} sm={6} xs={12} md={6}>
                            <TextInput variant={'filled'} label={'Calculated price'} value={data.calculated_price}
                                       readOnly
                                       onChange={() => {
                                       }}/>
                        </Grid.Col>

                        <Grid.Col lg={6} sm={6} xs={12} md={6}>
                            <TextInput variant={'filled'} label={'Applied pricing rule'}
                                       value={upperFirst(data.applied_pricing_rule_type)}
                                       readOnly
                                       onChange={() => {
                                       }}/>
                        </Grid.Col>

                        <Grid.Col lg={6} sm={6} xs={12} md={6}>
                            <TextInput variant={'filled'} label={'Margin'}
                                       value={data.applied_pricing_rule && data.applied_pricing_rule.margin ? `${data.applied_pricing_rule.margin}%` : ''}
                                       readOnly
                                       onChange={() => {
                                       }}/>
                        </Grid.Col>
                        <Grid.Col lg={6} sm={6} xs={12} md={6}>
                            <TextInput variant={'filled'} label={'Discount'}
                                       value={data.applied_pricing_rule && data.applied_pricing_rule.discount ? `${data.applied_pricing_rule.discount}%` : ''}
                                       readOnly
                                       onChange={() => {
                                       }}/>
                        </Grid.Col>

                    </Grid>
                </div>
                <Divider mb={15} mt={15}/>
            </>
            }


            <div className={'product__section_content'}>
                <Stack>
                    <TextInput label={'Base price'}
                               value={data.price || ''}
                               onChange={(e) => onDataChange({price: e.target.value})}/>

                    {/*<Input.Wrapper label={'VAT tariff'}>*/}
                    {/*    <Select data={pvmTariffOptions}*/}
                    {/*            radius={0}*/}
                    {/*            value={data.vat_tariff}*/}
                    {/*            onChange={(op) => onDataChange({vat_tariff: op})}/>*/}
                    {/*</Input.Wrapper>*/}

                    <TextInput label={'Šviesos price'}
                               value={data.sviesos_price || ''}
                               onChange={(e) => onDataChange({sviesos_price: e.target.value})}/>

                    <MultiSelect label={'VAT tariff groups'} data={pvmTariffOptions2}/>

                    <Textarea label={'VAT group'}
                              defaultValue={'Group_AT_A_BE_A_BG_A_CY_A_CZ_A_DE_A_DK_A_EE_A_ES_A_FI_A_FR_A'}/>
                </Stack>
            </div>
        </section>
        <section className={classes.product_section}>
            <Button compact type={"submit"}>Save</Button>
        </section>
    </form>
}
export default ProductAttributes

const pvmTariffOptions2: any = [
    'AT20', 'BE21', 'BG20', 'CY19', 'CZ21', 'DE25', 'DK20',
]
