import '../sass/app.scss'
// @ts-ignore
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';

import AppRouter from "./AppRouter";
import { Sanctum } from 'react-sanctum';

const app = ReactDOM.createRoot(document.getElementById('root')!);

const sanctumConfig = {
    apiUrl: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://localhost:8000" : 'https://pegasas-api.avaro.dev',
    csrfCookieRoute: "sanctum/csrf-cookie",
    signInRoute: "api/login",
    signOutRoute: "api/logout",
    userObjectRoute: "api/user",
};

app.render(
    <Router>
        <Sanctum config={sanctumConfig}>
        <AppRouter/>
        </Sanctum>
    </Router>
)
