import {Button, Divider, Modal, NumberInput, Popover, Select, TextInput, Box, LoadingOverlay} from "@mantine/core";
import {getErrorMessage} from "../helpers/helpers";
import {getSupplierOptions, useBaseObjectForm} from "../customHooks/hooks";
import CategoryNest from "./CategoryNest";
import {useEffect, useState} from "react";
import {getSupplierCategories, searchProduct, storePricingRule} from "../backend/axios";
import SearchInput from "./inputs/SearchInput";
import {X} from "tabler-icons-react";
import axios from "axios";

const FullPricingRuleModal = (props) => {
    const {
        open = false,
        setOpen = () => {
        },
        onCreate = () => {
        },
        selected = undefined
    } = props

    const [loading, setLoading] = useState(false)
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [loadingSearch, setLoadingSearch] = useState(false)
    const [productOptions, setProductOptions] = useState([])

    const [axiosToken, setAxiosToken]: any = useState();
    const [searchTimeout, setSearchTimeout]: any = useState();
    const [openCategoryPicker, setOpenCategoryPicker] = useState(false)

    const [categoryList, setCategoryList] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([])
    const {data, errors, setErrors, onDataChange}: any = useBaseObjectForm({
            supplier: '',
            category: '',
            product: '',
            discount: '',
            margin: '',
            ...selected
        }
    )
    useEffect(() => {
        getSupplierOptions({setOptions: setSupplierOptions, setLoading: setLoading})
    }, [])

    const onSubmit = (e = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        storePricingRule({
            supplier_id: data.supplier ? data.supplier : '',
            category_id: data.category && data.category.id ? data.category.id : '',
            product_id: data.product && data.product.value ? data.product.value : '',
            margin: data.margin,
            discount: data.discount
        })
            .then(r => {
                if (onCreate && r && r.data) onCreate(r.data)
                setLoading(false)
            })
            .catch(e => {
                if (e && e.response && e.response.data && e.response.data.errors) setErrors(e.response.data.errors)
                setLoading(false)
            })
    }

    const onSelectSupplier = (op) => {
        if (op) {
            onDataChange({supplier: op})
        }
    }

    useEffect(() => {
        if (data.supplier) {
            setLoadingCategories(true)
            getSupplierCategories({supplier: data.supplier})
                .then(r => {
                    if (r && r.data) setCategoryList(r.data)
                    setLoadingCategories(false)
                })
                .catch(() => {
                    setLoadingCategories(false)
                })
        }
    }, [data.supplier])

    const onSearchChange = (query) => {
        if (searchTimeout !== undefined) clearTimeout(searchTimeout)
        if (axiosToken !== undefined) axiosToken.cancel();

        let _token = axios.CancelToken.source();
        setAxiosToken(_token);
        setProductOptions([])

        let _timeout = setTimeout(() => {
            setLoadingSearch(true)
            searchProduct({supplier: data.supplier, category: data.category.id, product: query}, _token.token)
                .then(r => {
                    if (r && r.data) setProductOptions(r.data)
                    setLoadingSearch(false)
                })
        }, 500)

        setSearchTimeout(_timeout)
    }

    return <Modal title={'Create pricing rule'} opened={open} centered onClose={() => setOpen(false)}>
        <form onSubmit={onSubmit}>
            <LoadingOverlay visible={loading}/>
            <Select label={'Supplier'}
                    placeholder={'Pasirinkti'}
                    value={data.supplier}
                    error={getErrorMessage(errors, 'supplier')}
                    onChange={(op) => onSelectSupplier(op)}
                    data={supplierOptions}/>


            <Popover opened={openCategoryPicker} width={'target'} disabled={!data.supplier}>
                <Popover.Target>
                    <TextInput label={'Category'}
                               readOnly
                               onClick={() => setOpenCategoryPicker(!openCategoryPicker)}
                               disabled={!data.supplier || loadingCategories}
                               error={getErrorMessage(errors, 'category')}
                               value={data.category && data.category.name ? data.category.name : ''}
                               onChange={() => {
                               }}
                               placeholder={'Pasirinkti'}/>
                </Popover.Target>
                <Popover.Dropdown>
                    <Box>
                        <CategoryNest nest={categoryList}
                                      allowHighlight
                                      selected={data.category && data.category.id}
                                      setSelected={(cat) => {
                                          setOpenCategoryPicker(false)
                                          onDataChange({category: {id: cat.id, name: cat.name}})
                                      }}/>
                    </Box>
                </Popover.Dropdown>
            </Popover>

            {
                data.product && data.product.value ?
                    <Select value={data.product.value}
                            label={'Product'}
                            data={[data.product]}
                            rightSection={<X color={'red'} size={18} strokeWidth={1}
                                             onClick={() => onDataChange({product: null})}/>}/>
                    :
                    <SearchInput label={'Product'}
                                 error={getErrorMessage(errors, 'product')}
                                 onSearchChange={(q) => onSearchChange(q)}
                                 onSelectOption={(item) => {
                                     onDataChange({product: item})
                                 }}
                                 options={productOptions}
                                 loading={loadingSearch}/>
            }


            <Divider mt={20} mb={10}/>

            <NumberInput label={'Discount'}
                         value={data.discount}
                         rightSection={'%'}
                         precision={4}
                         disabled={!data.supplier && !data.category && !data.product}
                         error={getErrorMessage(errors, 'discount')}
                         onChange={(num) => onDataChange({discount: num})}/>
            <NumberInput label={'Margin'}
                         value={data.margin}
                         rightSection={'%'}
                         precision={4}
                         disabled={!data.supplier && !data.category && !data.product}
                         error={getErrorMessage(errors, 'margin')}
                         onChange={(num) => onDataChange({margin: num})}/>

            <div style={{marginTop: 15}}>
                <Button size={'xs'} compact type={'submit'}
                        disabled={!data.supplier && !data.category && !data.product}>Submit</Button>
            </div>

        </form>
    </Modal>
}

export default FullPricingRuleModal
