import {ActionIcon, Button, Input, JsonInput, LoadingOverlay, Select, Table} from "@mantine/core";
import {Message2Code, Plus, Trash, X} from "tabler-icons-react";
import {useEffect, useState} from "react";
import {
    deleteSupplierTemplateItem,
    getSupplierTemplate,
    saveSupplierTemplate
} from "../../../backend/axios";
import {useParams} from "react-router-dom";
import {showNotification} from "@mantine/notifications";
import {getErrorMessage} from "../../../helpers/helpers";

const SupplierTemplateForm = ({classes}) => {
    const params = useParams()
    const [showCode, setShowCode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [template, setTemplate]: any = useState({})
    const [productFields, setProductFields]: any = useState([])
    const [availableFields, setAvailableFields]: any = useState([])
    const [errors, setErrors]: any = useState({})

    const onSave = (e = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        saveSupplierTemplate(params.id, template)
            .then(r => {
                setResponse(r)
                showNotification({message: 'Data saved', color: 'green'})
                setLoading(false)
            })
            .catch(e => {
                if (e && e.response && e.response.data && e.response.data.errors) {
                    showNotification({message: 'Check form for errors', color: 'red'})
                    setErrors(e.response.data.errors)
                }
                setLoading(false)
            })
    }

    const onDelete = (item, index) => {
        setLoading(true)
        deleteSupplierTemplateItem(params.id, item.id)
            .then(r => {
                if (r && r.status === 200) {
                    let _data = [...template]
                    _data.splice(index, 1)
                    setTemplate(_data)
                    showNotification({message: 'Rule deleted', color: 'red'})
                }
                setLoading(false)
            })
            .catch(e => {
                if (e && e.response && e.response.data && e.response.data.errors) setErrors(e.response.data.errors)
                setLoading(false)
            })
    }

    const getData = (e = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        getSupplierTemplate(params.id)
            .then(r => {
                setResponse(r)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const setResponse = (r) => {
        if (r && r.data) {
            if (r.data.import_fields) setTemplate(r.data.import_fields)
            if (r.data.product_fields) setProductFields(r.data.product_fields)
            if (r.data.available_fields) setAvailableFields(r.data.available_fields)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const getAvailableOptions = (op) => {
        let _selected: any = template.map(item => item.product_field)
        let _fields = akeneoOptions.filter(item => {
            if (productFields.includes(item.value)) return item
        })
        return _fields.filter(item => {
            if (!_selected.includes(item.value) || item.value === op) return item
        })
    }

    const addNewRow = () => {
        let _template: any = [...template]
        _template.unshift({product_field: '', fields: ['..'], actions: []})
        setTemplate(_template)
    }

    const onRowChange = (field, val, index, errorField = '') => {
        let _template: any = [...template]
        _template[index][field] = val
        setTemplate(_template)
        if (errorField) {
            let _errors = {...errors}
            delete _errors[errorField]
            setErrors(_errors)
        }
    }
    const onRowDeepChange = (field, val, index, xindex, errorField = '') => {
        let _template: any = [...template]
        _template[index][field][xindex] = val
        setTemplate(_template)
        if (errorField) {
            let _errors = {...errors}
            delete _errors[errorField]
            setErrors(_errors)
        }
    }

    const getJsonValueXML = (item) => {
        if (item.fields) {
            let _val = ''
            item.fields.map((xml, index) => {
                _val = _val + xml
                if (item.actions && item.actions[index]) {
                    let fnd = actionOptions.find(act => act.value === item.actions[index] || act.label === item.actions[index])
                    if (fnd) _val = _val + actionOptions.find(act => act.value === item.actions[index] || act.label === item.actions[index]).label

                    if (item.actions[index] === 'round') {

                    }
                }
            })
            return _val
        }
        return ''
    }

    const removeAction = (index, xindex) => {
        let _template: any = [...template]
        _template[index].fields.splice(xindex, 1)
        _template[index].actions.splice(xindex - 1, 1)
        setTemplate(_template)
    }

    return <div className={classes.content}
                style={{maxWidth: 1140, minHeight: loading ? 150 : 0, position: 'relative'}}>
        <div className={classes.content_header}>
            <div>Importavimo šablonas</div>
        </div>
        <LoadingOverlay visible={loading}/>

        <div className={classes.content_header}>
            <div style={{
                display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse', gap: 15, width: '100%'
            }}>
                <Button onClick={() => onSave()} compact size={'xs'}>Save</Button>
                <Button onClick={() => addNewRow()} compact size={'xs'}>Add</Button>
            </div>
        </div>

        <div>
            <Table className={'xmlTemplateListTable'}>
                <thead>
                <tr>
                    <th style={{width: 300}}>Product field</th>
                    <th>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>XML</div>
                            <ActionIcon size={20} onClick={() => setShowCode(!showCode)}>
                                <Message2Code/>
                            </ActionIcon>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    template && template.length > 0 && template.map((item, index) => <tr key={`template-${index}`}>
                        <td>
                            {
                                showCode
                                    ? <JsonInput readOnly radius={0} value={item.product_field}/>
                                    : <Select placeholder={'Pasirinkti'}
                                              searchable
                                              variant={'unstyled'}
                                              value={item.product_field}
                                              error={getErrorMessage(errors, `data.${index}.product_field`)}
                                              data={getAvailableOptions(item.product_field)}
                                              onChange={(op) => onRowChange('product_field', op, index, `data.${index}.product_field`)}/>
                            }
                        </td>
                        <td>
                            {
                                showCode
                                    ? <JsonInput readOnly radius={0} value={getJsonValueXML(item)}/>
                                    : <Input.Wrapper error={getErrorMessage(errors, `data.${index}.fields`)}>
                                        {
                                            item.fields && item.fields.map((xml, xindex) => <div
                                                key={`action_xml_row_${xindex}`}>
                                                {xindex > 0 && <Select placeholder={'Pasirinkti veiksmą'}
                                                                       searchable
                                                                       style={{paddingLeft: '12px'}}
                                                                       radius={0}
                                                                       variant={'unstyled'}
                                                                       value={item.actions[xindex - 1]}
                                                                       rightSection={<X color={'red'}
                                                                                        strokeWidth={1} size={16}
                                                                                        onClick={() => removeAction(index, xindex)}/>}
                                                                       data={actionOptions}
                                                                       onChange={(op) => onRowDeepChange('actions', op, index, xindex - 1, `data.${index}.fields`)}/>
                                                }
                                                <Select placeholder={'Pasirinkti xml lauką'}
                                                        searchable
                                                        value={xml}
                                                        radius={0}
                                                        variant={'filled'}
                                                        data={availableFields}
                                                        onChange={(op) => onRowDeepChange('fields', op, index, xindex, `data.${index}.fields`)}/>
                                            </div>)
                                        }


                                        <Button onClick={() => {
                                            let _template = [...template]
                                            _template[index].fields.push('..')
                                            setTemplate(_template)
                                        }}
                                                compact
                                                variant={'white'}
                                                size={'xs'}
                                                leftIcon={<Plus size={13}/>} fullWidth>
                                            Add action
                                        </Button>
                                    </Input.Wrapper>
                            }

                        </td>
                        <td>
                            <ActionIcon color={'red.5'} size={"sm"} m={'auto'} onClick={() => onDelete(item, index)}>
                                <Trash size={18}/>
                            </ActionIcon>
                        </td>
                    </tr>)
                }
                </tbody>
            </Table>
            <div style={{marginTop: 15}}>
                <Button compact size={'xs'} onClick={() => onSave()}>Save</Button>
            </div>
        </div>
    </div>
}

export default SupplierTemplateForm

const akeneoOptions = [
    {label: 'Formatas', value: 'format'},
    {label: 'Svoris', value: 'weight'},
    {label: 'Aukštis', value: 'height'},
    {label: 'Plotis', value: 'width'},
    {label: 'Storis', value: 'depth'},
    {label: 'Kaina', value: 'price'},
    {label: 'PVM lt', value: 'vat_lt'},
    {label: 'PVM es', value: 'vat_es'},
    {label: 'Pardavimo statusas', value: 'sold_status'},
    {label: 'Pardavimo kanalas', value: 'sold_channel'},
    {label: 'Gamykla', value: 'factory'},
    {label: 'Likutis', value: 'stock'},
    {label: 'SKU', value: 'sku'},
    {label: 'EAN kodas', value: 'ean'},
    {label: 'BIC', value: 'bic'},
    {label: 'Leidinio pavadinimas', value: 'name'},
    {label: 'Anotacija', value: 'anotacija'},
    {label: 'Tikslinė grupė', value: 'group'},
    {label: 'Galerija', value: 'gallery'},
    {label: 'Prekės ženklas/Leidyba', value: 'label'},
    {label: 'brand', value: 'brand'},
    {label: 'age group', value: 'age_group'},
    {label: 'annotation', value: 'annotation'},
]

const actionOptions = [
    {label: '+', value: 'add'},
    {label: '-', value: 'sub'},
    {label: '*', value: 'mult'},
    {label: '/', value: 'div'},
    {label: "' '", value: 'concat_ws'},
    {label: "''", value: 'concat'},
    {label: "x", value: 'concat_x'},
    {label: "/n", value: 'concat_nl'},
]
