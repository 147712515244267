import {createStyles, List, LoadingOverlay, Select} from "@mantine/core";
import CategoryNest from "../../../components/CategoryNest";

const CategoriesListExtension = ({
                                     suppliers = [],
                                     selected,
                                     setSelected,
                                     supplier,
                                     onSupplierChange,
                                     catList,
                                     loading = false
                                 }) => {
    const {classes} = useStyles()

    const nestList = (nest) => {
        return nest.map((li, index) =>
            <List withPadding listStyleType="disc" key={`li-${index}`}>
                <List.Item>{li.name}</List.Item>
                {
                    li && li.nest && nestList(li.nest)
                }
            </List>
        )
    }

    return <>
        <div className={classes.header}>
            <LoadingOverlay visible={loading}/>
            <div className={'navigation__section_name'}>
                <Select data={suppliers}
                        disabled={loading}
                        placeholder={'Supplier'}
                        styles={fieldStyles}
                        value={supplier ? Number(supplier) : ''}
                        onChange={(op) => onSupplierChange(op.toString())}
                        variant={'subtle'}/>
            </div>

            {
                catList && catList.length > 0 && <div className={'navigation__link_wrapper'}>
                    <CategoryNest nest={catList} setSelected={setSelected} selected={selected} allowHighlight/>
                </div>
            }

        </div>
    </>
}
export default CategoriesListExtension

const fieldStyles = {
    label: {
        fontSize: '0.65rem',
        color: 'rgb(163,166,175)'
    }
}

const useStyles = createStyles((theme) => ({
    header: {
        position: 'relative',
        '.navigation__section_name': {
            marginBottom: 10,
            textTransform: 'uppercase',
            letterSpacing: 1.1,
            color: 'rgb(163,166,175)',
            fontSize: '0.75rem',
            fontWeight: 500,
            borderBottom: '1px solid #e0e0e0'
        },
        '.navigation__link_wrapper': {
            display: 'flex',
            flexDirection: 'column',
            gap: 5
        },
        '.navigation__section_link': {
            color: 'rgb(163,166,175)',
            fontSize: '0.75rem',
            fontWeight: 500,
            cursor: 'pointer'
        },
        '.navigation__section_link.active': {
            color: theme.colors.brand[6],
            fontSize: '0.75rem',
            fontWeight: 500,
        }
    },

    footer: {
        marginTop: "auto",
        marginBottom: 20,
        paddingTop: 20,
        borderTop: '1px solid #e0e0e0',
        '.navigation__section_name': {
            paddingBottom: 5,
            marginBottom: 5,
            textTransform: 'uppercase',
            letterSpacing: 1.3,
            color: 'rgb(163,166,175)',
            fontSize: '0.75rem',
            fontWeight: 500,
        }
    },
    categoryItem: {
        marginLeft: 10,
        fontSize: 14,
        cursor: 'pointer',
    }
}));

