import {ActionIcon, Table} from "@mantine/core";
import {Pencil, Plus, Trash} from "tabler-icons-react";
import {useState} from "react";
import PricingRuleModal from "../../../components/PricingRuleModal";
import {openConfirmModal} from "@mantine/modals";
import {deletePricingRule} from "../../../backend/axios";
import {showNotification} from "@mantine/notifications";

const SuppliersPricingRulesList = ({
                                       classes, data, setData, refreshSupplier = () => {
    }
                                   }) => {
    const [openPricingModal, setOpenPricingModal] = useState(false)

    const onSave = (item) => {
        setData({...data, pricing_rule: item})
        setOpenPricingModal(false)
    }

    const onDeleteRule = (item) => {
        openConfirmModal({
            title: `Confirm rule delete`,
            labels: {confirm: 'Delete', cancel: 'Cancel'},
            centered: true,
            confirmProps: {
                compact: true,
                variant: 'white', size: 'xs'
            },
            cancelProps: {
                compact: true,
                variant: 'filled', size: 'xs'
            },
            onConfirm: () => onDelete(item),
        })
    }

    const onDelete = (item) => {
        if (item && item.id) deletePricingRule(item.id)
            .then(r => {
                if (r && r.status === 200) {
                    setData({...data, pricing_rule: null})
                    refreshSupplier()
                    showNotification({color: 'red', message: 'Rule deleted'})
                }
            })
            .catch(e => {
                if (e && e.response && e.response.status) showNotification({
                    color: 'red',
                    message: `Error ${e.response.status}`
                })
            })
    }

    return <>
        {
            openPricingModal &&
            <PricingRuleModal open={openPricingModal}
                              type={'supplier'}
                              onSave={onSave}
                              onCreate={onSave}
                              setOpen={setOpenPricingModal}
                              selected={
                                  {
                                      id: data.pricing_rule && data.pricing_rule.id ? data.pricing_rule.id : '',
                                      supplier: data.id,
                                      margin: data.pricing_rule && data.pricing_rule.margin ? data.pricing_rule.margin : '',
                                      discount: data.pricing_rule && data.pricing_rule.discount ? data.pricing_rule.discount : ''
                                  }
                              }/>
        }
        <div className={classes.content} style={{maxWidth: 1140}}>
            <div className={classes.content_header}>
                <div>Pricing rule</div>
                <ActionIcon color={'brand'} onClick={() => {
                    setOpenPricingModal(true)
                }}>
                    <Plus strokeWidth={2} size={20}/>
                </ActionIcon>
            </div>

            {
                data.pricing_rule && data.pricing_rule.id &&
                <div className={'product__section_content'}>
                    <Table className={'ruleListTable'}>
                        <thead>
                        <tr>
                            <th>Discount</th>
                            <th>Margin</th>
                            <th style={{width: '0'}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{`${data.pricing_rule.discount} %`}</td>
                            <td>{`${data.pricing_rule.margin} %`}</td>
                            <td>
                                <div style={{display: 'flex', gap: 5}}>
                                    <ActionIcon size={22} color={'red.6'}
                                                onClick={() => onDeleteRule(data.pricing_rule)}>
                                        <Trash size={25} strokeWidth={1.2}/>
                                    </ActionIcon>
                                    <ActionIcon size={22} color={'gray.6'}
                                                onClick={() => setOpenPricingModal(true)}>
                                        <Pencil size={25} strokeWidth={1.2}/>
                                    </ActionIcon>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            }
        </div>
    </>
}
export default SuppliersPricingRulesList
