import NestedList from "./NestedList";

const CategoryNest = ({
                          nest,
                          selected = '',
                          allowCreate = false,
                          setSelected = () => {
                          },
                          allowHighlight = false
                      }: any) => {

    return <>

        {
            nest && nest.length > 0 && nest.map((li, li_i) => {
                return <NestedList key={`${li_i}-in`}
                                   nest={li}
                                   selected={selected}
                                   allowCreate={allowCreate}
                                   allowHighlight={allowHighlight}
                                   setSelected={setSelected}/>
            })
        }
    </>
}
export default CategoryNest
